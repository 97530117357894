import { CSMCommand, CSMMisoFrame } from '../components/serial/csm';
import { RefDesignProtocol } from '../ref_design_protocol';
import { PartitionValue, RecordValue } from '../services/cache/idb';
import { thimphuProtocol } from '../thimphu_protocol';

export interface QuestionningResult {
  label: string;
  point: [number, number];
  data?: Plotly.Data[];
  layout?: Partial<Plotly.Layout>;
}

export interface SignatureWithSpotgrid {
  recordKey: string;
  signature: number[];
  spotsgrid: number[];
  deltaHumidity?: number;
  deltaTemperature?: number;
}

export enum SessionType {
  Records = 'Records',
  Models = 'Saved models',
}

// Hardware types
export interface ThimphuMessage {
  id: string;
  message: thimphuProtocol.PlatformMsg;
}

export interface RefkitMessage {
  id: string;
  ts: number; // Date.now()
  message: RefDesignProtocol.SlaveRoot;
}

export interface CSMMisoMessage {
  id: string;
  ts: number; // Date.now()
  message: CSMMisoFrame;
}

export interface CSMCommandMessage {
  id: string;
  message: CSMCommand;
}

export interface HIHvalues {
  humidity: number;
  temperature: number;
}

// api type
export interface ApiErrorType {
  Reason: string;
}

export interface ApiRecord {
  ID: RecordValue['key'];
  AbsoluteTimestamp: RecordValue['absoluteTimestamp'];

  Name: RecordValue['name'];
  Description: RecordValue['description'];

  DeviceCommonName: RecordValue['device']['commonName'];
  DeviceShellSerial: RecordValue['device']['shellSerial'];
  DeviceCoreSensorSerial: RecordValue['device']['coreSensorSerial'];
  DeviceFwVersion: RecordValue['device']['fwVersion'];
  DeviceHwVersion: RecordValue['device']['hwVersion'];
  DeviceCameraExposure: RecordValue['device']['cameraExposure'];
  DeviceSpotsgrid: RecordValue['device']['spotsgrid'];

  BaselineStart: RecordValue['baselineStart'];
  BaselineEnd: RecordValue['baselineEnd'];
  AnalyteStart: RecordValue['analyteStart'];
  AnalyteEnd: RecordValue['analyteEnd'];

  SensogramNFrames?: RecordValue['sensogramNFrames'];
}

export interface ApiPartition {
  ID: PartitionValue['key'];
  AbsoluteTimestamp: PartitionValue['absoluteTimestamp'];

  NFrames: PartitionValue['nFrames'];
  NDims: PartitionValue['nDims'];

  RealtiveTimestamps: PartitionValue['relativeTimestamps'];
  Series: PartitionValue['series'];
}

export enum ApiParitionDataType {
  Sensogram = 1,
  Humidity = 2,
  Temperature = 3,
}

export interface ApiDOHSessionResponse {
  SessionID: string;
}
export enum ApiResponseType {
  JSON = 'json',
  ArrayBuffer = 'arraybuffer',
}
