import { ApiRecord } from '../../types/types';
import { RecordValue } from '../cache/idb';
import { callAPIEndpoint } from './api';

export const apiPostRecord = async (record: RecordValue, userID: string) => {
  let apiRecord: ApiRecord = {
    ID: record.key,
    AbsoluteTimestamp: record.absoluteTimestamp,

    Name: record.name,
    Description: record.description,

    DeviceCommonName: record.device.commonName,
    DeviceShellSerial: record.device.shellSerial,
    DeviceCoreSensorSerial: record.device.coreSensorSerial,
    DeviceFwVersion: record.device.fwVersion,
    DeviceHwVersion: record.device.hwVersion,
    DeviceCameraExposure: record.device.cameraExposure,
    DeviceSpotsgrid: record.device.spotsgrid,

    BaselineStart: record.baselineStart,
    BaselineEnd: record.baselineEnd,
    AnalyteStart: record.analyteStart,
    AnalyteEnd: record.analyteEnd,
  };
  let jsonBody = JSON.stringify(apiRecord);
  void (await callAPIEndpoint(`/record?user_id=${userID}`, 'POST', jsonBody));
};

export const apiGetUserRecordKeys = async (userID: string) => {
  let recordKeys: string[] = await callAPIEndpoint(`/user/record/ids?user_id=${userID}`, 'GET');
  return recordKeys;
};

export const apiGetRecord = async (recordKey: string) => {
  let apiRecord: ApiRecord = await callAPIEndpoint(`/record?record_id=${recordKey}`, 'GET');
  return apiRecord;
};
