import { FC, useState } from 'react';
import { loadSyncEnabled, saveSyncEnabled } from '../services/cache/localStorage';
import { useOktaAuth } from '@okta/okta-react';
import { Avatar, Button, Col, Row, Switch, Tag, Tooltip } from 'antd';
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import { colorHexToRGBA, ARYBALLE_COLOR_CYAN, ARYBALLE_COLOR_GRAY, ARYBALLE_COLOR_BLUE_DARK } from '../utils/helpers/utils';
import { FlexCol, FlexRow, Paper } from '../components/common/common';

export const ProfilePage: FC = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [syncEnabled, setSyncEnabled] = useState(loadSyncEnabled());

  return (
    <Row
      style={{
        height: '100%',
      }}
    >
      <Col
        xs={24}
        md={12}
        style={{
          height: '100%',
        }}
      >
        <Paper style={{}}>
          <FlexCol
            style={{
              gap: 10,
            }}
          >
            <Avatar
              size="large"
              style={{
                backgroundColor: authState && authState.isAuthenticated ? colorHexToRGBA(ARYBALLE_COLOR_CYAN, 1) : colorHexToRGBA(ARYBALLE_COLOR_GRAY, 0.7),
                cursor: 'pointer',
              }}
              icon={<UserOutlined />}
            />
            {authState && authState.isAuthenticated ? (
              <FlexCol
                style={{
                  height: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'stretch',
                  gap: 5,
                }}
              >
                <FlexCol>
                  <b>{authState.idToken?.claims.name}</b>
                  <i>{authState.idToken?.claims.email}</i>
                  <Row
                    gutter={[5, 5]}
                    style={{
                      marginTop: 30,
                    }}
                  >
                    <Col xs={24}>
                      Member of the following <b>groups</b>:
                    </Col>
                    {(authState.idToken?.claims.groups as string[]).map((group) => {
                      return (
                        <Col>
                          <Tag color={colorHexToRGBA(ARYBALLE_COLOR_BLUE_DARK, 0.7)}>
                            <span style={{ fontSize: 14 }}>{group.toUpperCase()}</span>
                          </Tag>
                        </Col>
                      );
                    })}
                  </Row>
                </FlexCol>
                <FlexRow
                  style={{
                    marginTop: 50,
                    gap: 10,
                    alignItems: 'center',
                  }}
                >
                  <Tooltip overlay={<>Enable cloud sync to be able to save your records to the cloud</>}>
                    <InfoCircleOutlined /> Enable cloud sync:
                  </Tooltip>
                  <Switch
                    checked={syncEnabled}
                    onChange={(checked) => {
                      setSyncEnabled(checked);
                      saveSyncEnabled(checked);
                    }}
                  />
                </FlexRow>
              </FlexCol>
            ) : (
              <FlexCol
                style={{
                  alignItems: 'stretch',
                  gap: 1,
                }}
              >
                <i>You are not logged in</i>
                <i>Cloud Sync is not available</i>
                <Button
                  type="primary"
                  onClick={() => {
                    return oktaAuth.signInWithRedirect();
                  }}
                >
                  Login
                </Button>
              </FlexCol>
            )}
          </FlexCol>
        </Paper>
      </Col>
    </Row>
  );
};
