import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Avatar, Button, Popover, Switch, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FlexCol, FlexRow } from '../components/common/common';
import { ARYBALLE_COLOR_CYAN, ARYBALLE_COLOR_GRAY, colorHexToRGBA } from '../utils/helpers/utils';
import { apiPostUser } from '../services/api/api';
import { Link } from 'react-router-dom';
// import { loadSyncEnabled, saveSyncEnabled } from "../localStorage"

export const LoginWidget = () => {
  const { authState, oktaAuth } = useOktaAuth();
  // const [syncEnabled, setSyncEnabled] = useState(loadSyncEnabled())

  useEffect(() => {});

  useEffect(() => {
    if (!authState) {
      return;
    }
    console.log('auth: state ok', authState);
    if (authState.isAuthenticated && authState.idToken && authState.idToken.claims && authState.idToken.claims.email) {
      let firstName = '';
      let lastName = '';
      if (authState.idToken.claims.name) {
        [lastName, firstName] = authState.idToken.claims.name.split(' ');
      }
      apiPostUser(authState.idToken.claims.sub, authState.idToken.claims.email, firstName, lastName, authState.idToken.claims.groups as string[]).then((res) => {
        console.log('api: post user ok', res);
      });
    }
  }, [authState, oktaAuth]);

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      content={
        <FlexCol
          style={{
            gap: 3,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {authState && authState.isAuthenticated ? (
            <FlexCol
              style={{
                alignItems: 'stretch',
                gap: 1,
              }}
            >
              <FlexCol
                style={{
                  gap: 1,
                  alignItems: 'center',
                }}
              >
                <b>{authState.idToken?.claims.name}</b>
                <i>{authState.idToken?.claims.email}</i>
              </FlexCol>
              <FlexRow>
                <Link to="/profile">
                  <Button block>Profile</Button>
                </Link>
                <Button
                  block
                  type="default"
                  onClick={() => {
                    return oktaAuth.signOut();
                  }}
                >
                  Logout
                </Button>
              </FlexRow>
            </FlexCol>
          ) : (
            <FlexCol
              style={{
                alignItems: 'stretch',
                gap: 1,
              }}
            >
              <i>You are not logged in</i>
              <i>Cloud Sync is not available</i>
              <Button
                type="primary"
                onClick={() => {
                  return oktaAuth.signInWithRedirect();
                }}
              >
                Login
              </Button>
            </FlexCol>
          )}
        </FlexCol>
      }
    >
      <Tooltip placement="left" overlay={authState && authState.isAuthenticated ? <>Logged in as {authState.idToken?.claims.name}</> : <>Click to log in</>}>
        <Avatar
          size="large"
          style={{
            backgroundColor: authState && authState.isAuthenticated ? colorHexToRGBA(ARYBALLE_COLOR_CYAN, 1) : colorHexToRGBA(ARYBALLE_COLOR_GRAY, 0.7),
            cursor: 'pointer',
          }}
          icon={<UserOutlined />}
        />
      </Tooltip>
    </Popover>
  );
};
