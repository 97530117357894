import { Col, Row } from 'antd';
import SectionPage from '../../../components/widgets/section/SectionPage';
import { useEffect, useState } from 'react';
import { ModelType } from '../../../utils/helpers/byteio/model';
import { QuestionningResult } from '../../../types/types';
import Plot from 'react-plotly.js';
import { loadModel } from '../../../services/cache/localStorage';
import { classifySignature } from '../../../components/analysis/classifier';
import { DEFAULT_PLOTLY_CONFIG, DEFAULT_PLOTLY_LAYOUT } from '../../../utils/constants/constants';
import { getSignatureFigure } from '../../../components/analysis/figures';
import { signature } from '../../../components/analysis/definitions';

type GenericDisplayProps = {
  result: QuestionningResult | null;
};

const GenericDisplay: React.FC<GenericDisplayProps> = ({ result }) => {
  return (
    <SectionPage>
      <Col span={24}>
        <Row justify="center" align="middle" style={{ height: '80vh' }}>
          {result?.data !== undefined && result?.layout !== undefined && (
            <Plot
              data={result?.data}
              layout={result?.layout}
              config={DEFAULT_PLOTLY_CONFIG}
              style={{
                width: '100%',
                height: '100%',
                margin: 'auto',
              }}
              useResizeHandler
            />
          )}
        </Row>
      </Col>
    </SectionPage>
  );
};

export default GenericDisplay;
