import { Button, Tooltip } from 'antd';
import { BoxPlotOutlined, BoxPlotTwoTone, ForwardOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import uPlot from 'uplot';
import { SecondData } from '../../../pages/ChemicalFamilyIdentificationPage/CsmChemicalFamilyIdentificationPage';
import { mean } from '../../analysis/utils';
import { DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, PEPTIDE_COLOR_MAP_VDW, rowIdxToLetter, spotsgrid1dIndexTo2dCoordinates } from '../../../utils/helpers/utils';
import { FlexRow, Paper } from '../../common/common';
import { useMessageContext } from '../../../state/context/MessageContext';

type MziChartProps = {
  aggregatedIndicesMap: Record<number, number[]>;
  currentSpotsgrid1d: number[] | null;
  currentSpotsgrid1dWithPeptide: number[] | null;
  finalRawMZI: React.MutableRefObject<number[] | null>;
  seriesLabelsAsProps: React.MutableRefObject<number[]>;
  onClickReset: () => void;
};

const MziChart: React.FC<MziChartProps> = ({ aggregatedIndicesMap, currentSpotsgrid1d, currentSpotsgrid1dWithPeptide, finalRawMZI, seriesLabelsAsProps, onClickReset }) => {
  const { hihValues } = useMessageContext();

  // * plot tooling
  const [shouldAggregate, setShouldAggregate] = useState<boolean>(true);
  const [shouldRedraw, setShouldRedraw] = useState<boolean>(false);
  const PLOT_WINDOW_SIZE = 180;

  // ** MZI
  const MziTargetRef = useRef<HTMLDivElement>(null);
  const mziTooltipRef = useRef<HTMLDivElement>(null);

  const [MziUplotData, setMziUplotData] = useState<uPlot.AlignedData>([]);
  const MziUplotRef = useRef<uPlot | null>(null);
  const [MziUplotOptions, setMziUplotOptions] = useState<uPlot.Options | null>(null);

  // time series
  const decimatedTimestampSeriesRef = useRef<number[]>([]);
  const decimatedRatioSeriesRef = useRef<number[][]>([]);
  const decimatedMZISeriesRef = useRef<number[][]>([]);

  const humiditySeriesRef = useRef<number[]>([]);

  const avgMZISeriesRef = useRef<number[]>([]);
  const avgRatioSeriesRef = useRef<number[]>([]);

  useEffect(() => {
    if (finalRawMZI.current && finalRawMZI.current.length > 0) {
      let MZIdataDisplay = finalRawMZI.current;
      let humidity = hihValues.humidity;

      // update decimatedMZISeriesRef and avgMZI
      decimatedMZISeriesRef.current.push(MZIdataDisplay);
      avgMZISeriesRef.current.push(mean(MZIdataDisplay));
      // humidity display
      humiditySeriesRef.current.push(humidity);
      // x data is time
      decimatedTimestampSeriesRef.current.push(Date.now());

      // shrinking buffers for better display experience

      if (decimatedMZISeriesRef.current.length > PLOT_WINDOW_SIZE) {
        decimatedMZISeriesRef.current.shift();
      }
      if (avgMZISeriesRef.current.length > PLOT_WINDOW_SIZE) {
        avgMZISeriesRef.current.shift();
      }
      if (decimatedTimestampSeriesRef.current.length > PLOT_WINDOW_SIZE) {
        decimatedTimestampSeriesRef.current.shift();
      }

      if (humiditySeriesRef.current.length > PLOT_WINDOW_SIZE) {
        humiditySeriesRef.current.shift();
      }

      if (decimatedTimestampSeriesRef.current.length > PLOT_WINDOW_SIZE) {
        decimatedTimestampSeriesRef.current.shift();
      }

      // ** build mzi uplot data

      // time stamp
      let X = decimatedTimestampSeriesRef.current.map((ts) => ts / 1000);

      // aggregate mzis timeseries (frame by frame) if needed
      let finalMZIsSeries: number[][] = [];
      if (shouldAggregate) {
        for (let j = 0; j < decimatedMZISeriesRef.current.length; j++) {
          let finalMZIs: number[] = [];
          for (let aggKey in aggregatedIndicesMap) {
            let aggIndices = aggregatedIndicesMap[aggKey];
            let sum = 0;
            for (let i = 0; i < aggIndices.length; i++) {
              sum += decimatedMZISeriesRef.current[j][aggIndices[i]];
            }
            finalMZIs.push(sum / aggIndices.length);
          }
          finalMZIsSeries.push(finalMZIs);
        }
      } else {
        finalMZIsSeries = decimatedMZISeriesRef.current;
      }
      // declare seriesLabel for aggregation
      let seriesLabels: number[] = [];
      if (shouldAggregate) {
        seriesLabels = Object.keys(aggregatedIndicesMap).map((aggKey) => parseInt(aggKey));
        seriesLabelsAsProps.current = seriesLabels;
      } else {
        if (currentSpotsgrid1dWithPeptide) {
          seriesLabels = currentSpotsgrid1dWithPeptide;
        }
      }

      if (decimatedRatioSeriesRef.current.length > PLOT_WINDOW_SIZE) {
        decimatedRatioSeriesRef.current.shift();
      }
      if (avgRatioSeriesRef.current.length > PLOT_WINDOW_SIZE) {
        avgRatioSeriesRef.current.shift();
      }

      // *** MZI PLOTS

      // mzi plot building
      if (MziUplotOptions === null && currentSpotsgrid1d !== null && finalMZIsSeries.length >= 1) {
        const opts: uPlot.Options = {
          id: `uplot-chart-mzi`,
          width: 0,
          height: 0,
          padding: [0, 50, 0, 0],
          legend: {
            show: false,
          },
          scales: {
            y: {
              range: (u, min, max) => (min < 0 ? [min, max] : [0, max]),
              // range: (u, min, max) => [-1, 1],
            },
            RH: {
              // Relative humidity scale
              range: (u, min, max) => [min, max],
            },
          },
          axes: [
            {}, // X axis
            {
              scale: 'y',
              label: 'Phase Shift (rad)',
            },
            {
              side: 1,
              scale: 'RH',
              label: 'Humidity (%RH)',
            },
          ],
          pxAlign: 0,
          series: [
            {},
            // sensogram series
            ...seriesLabels.map((spotInt) => {
              let color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;
              let peptideInt = spotInt;
              if (peptideInt < 0) {
                peptideInt *= -1;
              }
              let peptideStr = spotInt.toString();

              if (peptideStr.length === 3 && peptideStr[2] === '4') {
                peptideInt = parseInt(peptideStr.slice(0, 2));
              }
              if (PEPTIDE_COLOR_MAP_VDW[peptideInt]) {
                color = PEPTIDE_COLOR_MAP_VDW[peptideInt];
              }
              let label = peptideInt.toString();
              return {
                show: spotInt >= 1,
                spanGaps: false,
                label: label,
                stroke: color,
                width: 2,
                scale: 'y',
              } as uPlot.Series;
            }),
            // Avg series
            {
              show: true,
              spanGaps: false,
              label: 'Avg',
              stroke: 'rgba(0,0,0,0.7)',
              width: 2,
            } as uPlot.Series,
            // RH series
            {
              scale: 'RH',
              label: 'Humidity',
              stroke: 'rgba(0,128,255,0.7)',
              width: 2,
              dash: [10, 5],
            } as uPlot.Series,
          ],
          hooks: {
            setSeries: [
              (u, seriesIdx) => {
                try {
                  // console.log("sense page: set series", seriesIdx)
                  if (mziTooltipRef.current === null) {
                    return;
                  }
                  if (seriesIdx === null) {
                    return;
                  }
                  let seriesLabel = u.series[seriesIdx].label;
                  if (seriesLabel === undefined) {
                    return;
                  }
                  let peptideInt = parseInt(seriesLabel);
                  let color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;
                  if (seriesLabel === 'Avg') {
                    color = 'rgba(0,0,0,0.7)';
                  }
                  if (PEPTIDE_COLOR_MAP_VDW[peptideInt]) {
                    color = PEPTIDE_COLOR_MAP_VDW[peptideInt];
                  }
                  let tooltip = seriesLabel;
                  if (!shouldAggregate) {
                    let [row, col] = spotsgrid1dIndexTo2dCoordinates(seriesIdx - 1);
                    let rowStr = rowIdxToLetter(row);
                    tooltip += ` [${rowStr}${col}]`;
                  }
                  mziTooltipRef.current.innerHTML = `<b>${tooltip}</b>`;
                  mziTooltipRef.current.style.backgroundColor = color;
                  mziTooltipRef.current.style.color = 'white';
                  mziTooltipRef.current.style.border = '1px solid white';
                  mziTooltipRef.current.style.borderRadius = '5px';
                  mziTooltipRef.current.style.padding = '5px';
                } catch (e) {
                  console.log('sense page: set series error', e);
                }
              },
            ],
          },
          focus: {
            alpha: 0.3,
          },
          cursor: {
            focus: {
              prox: 10,
            },
          },
        };
        setMziUplotOptions(opts);

        let Ys = [];

        for (let i = 0; i < seriesLabels.length; i++) {
          let Y = [];
          for (let j = 0; j < decimatedMZISeriesRef.current.length; j++) {
            Y[j] = decimatedMZISeriesRef.current[j][i];
          }
          Ys.push(Y);
        }
        Ys.push(avgMZISeriesRef.current);

        Ys.push(humiditySeriesRef.current);
        let data: uPlot.AlignedData = [X, ...Ys];

        setMziUplotData(data);
      }

      if (MziUplotOptions !== null && currentSpotsgrid1d !== null) {
        let Ys = [];
        for (let i = 0; i < seriesLabels.length; i++) {
          let Y = [];
          for (let j = 0; j < finalMZIsSeries.length; j++) {
            Y[j] = finalMZIsSeries[j][i];
          }
          Ys.push(Y);
        }
        Ys.push(avgMZISeriesRef.current);
        // add humidity data in Ys
        Ys.push(humiditySeriesRef.current);
        let data: uPlot.AlignedData = [X, ...Ys];
        setMziUplotData(data);
      }
    }
  }, [finalRawMZI.current]);

  useEffect(() => {
    const cleanup = () => {
      console.info('sense page: destroying uplot refs');

      if (MziUplotRef.current !== null) {
        MziUplotRef.current.destroy();
        MziUplotRef.current = null;
      }
      setMziUplotOptions(null);
      setMziUplotData([]);
    };
    cleanup();
    return cleanup;
  }, []);

  useEffect(() => {
    if (MziUplotOptions !== null && MziUplotData !== null && MziTargetRef.current !== null) {
      if (MziUplotRef.current === null || shouldRedraw) {
        if (MziUplotRef.current !== null) {
          MziUplotRef.current.destroy();
          MziUplotRef.current = null;
        }
        let plot = new uPlot(MziUplotOptions, MziUplotData, MziTargetRef.current);
        plot.setSize({
          width: MziTargetRef.current.clientWidth,
          height: MziTargetRef.current.clientHeight,
        });
        MziUplotRef.current = plot;
        if (shouldRedraw) {
          setShouldRedraw(false);
        }
      } else {
        MziUplotRef.current.setData(MziUplotData);
        MziUplotRef.current.setSize({
          width: MziTargetRef.current.clientWidth,
          height: MziTargetRef.current.clientHeight,
        });
      }
    } else {
      console.log('sense page: could not create uplot', MziUplotOptions, MziUplotData, MziTargetRef.current);
      console.log('MZIUplotOptions', MziUplotOptions);
    }
  }, [MziUplotOptions, MziUplotData, MziTargetRef.current]);

  return (
    <>
      <FlexRow
        style={{
          width: '100%',
          justifyContent: 'end',
          alignItems: 'center',
          gap: 10,
          marginBottom: '10px',
        }}
      >
        <div ref={mziTooltipRef}></div>
        <Tooltip overlay="Toggle sensor aggregation">
          <Button
            type="text"
            size="large"
            onClick={() => {
              setMziUplotOptions(null);
              setMziUplotData([]);
              setShouldAggregate(!shouldAggregate);
              setShouldRedraw(true);
              decimatedMZISeriesRef.current = [];
            }}
            icon={shouldAggregate ? <BoxPlotTwoTone /> : <BoxPlotOutlined />}
          />
        </Tooltip>
        <Tooltip overlay="Reset to zero">
          <Button
            type="text"
            size="large"
            onClick={() => {
              onClickReset();
            }}
            icon={<VerticalAlignBottomOutlined />}
          />
        </Tooltip>
        <Tooltip overlay="Clear MZI chart">
          <Button
            type="text"
            size="large"
            onClick={() => {
              decimatedMZISeriesRef.current = [];
              decimatedTimestampSeriesRef.current = [];
            }}
            icon={<ForwardOutlined />}
          />
        </Tooltip>
      </FlexRow>

      {/* MZI Chart */}
      <FlexRow
        style={{
          width: '100%',
          height: '100%',
          padding: 3,
          borderRadius: 10,
          backgroundColor: 'transparent',
        }}
      >
        <Paper
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              margin: 'auto',
              height: 400,
              position: 'relative',
            }}
            ref={MziTargetRef}
          />
        </Paper>
      </FlexRow>
    </>
  );
};

export default MziChart;
