import { SwapOutlined } from '@ant-design/icons';
import { theme, Tooltip } from 'antd';
import { FC } from 'react';
import { FlexCol } from '../../common/common';

export const PairAndConnectivityWidget: FC<{
  name: string;
  isConnected: boolean;
  isPaired: boolean;
  onPair: () => Promise<void>;
}> = ({ name, isConnected, isPaired, onPair }) => {
  const {
    token: { colorSuccessText, colorInfoText, colorErrorText },
  } = theme.useToken();

  return (
    <FlexCol
      style={{
        alignItems: 'center',
        gap: 0,
      }}
    >
      <Tooltip
        overlayInnerStyle={{ textAlign: 'center' }}
        overlay={
          <>
            {(function () {
              if (isConnected) {
                return `${name} is connected`;
              } else {
                if (isPaired) {
                  return `${name} is paired to your browser. Simply plug the device in!`;
                } else {
                  return `Click to try to pair ${name}`;
                }
              }
            })()}
          </>
        }
      >
        <SwapOutlined
          style={{
            color: (function () {
              if (isConnected) {
                return colorSuccessText;
              } else {
                if (isPaired) {
                  return colorInfoText;
                } else {
                  return colorErrorText;
                }
              }
            })(),
            fontSize: 30,
          }}
          onClick={onPair}
        />
      </Tooltip>
      {name}
    </FlexCol>
  );
};
