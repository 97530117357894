import { FC, useEffect, useRef, useState } from 'react';
import { RecordKey, RecordValue, SENSOR_NATURE, deleteRecord, getAllRecordKeys, getAllRecords, getFullRecord, getRecord, getRecords, putPartition, putRecord, updateRecordDescription, updateRecordName, withIdb } from '../../../services/cache/idb';
import { FlexCol, FlexRow, Paper } from '../../../components/common/common';
import { Button, Col, Input, InputRef, Modal, Popconfirm, Row, Table, TableColumnType, Tooltip, Typography, message as antdMessage } from 'antd';
import { CloseOutlined, CloudDownloadOutlined, CloudUploadOutlined, DeleteOutlined, EyeOutlined, MinusSquareOutlined, PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, Redirect } from 'react-router-dom';
import { RecordsAnalysisWidget } from '../../../components/widgets/Graph/recordsAnalysisWidget';
import Highlighter from 'react-highlight-words';
import { deleteModelSigmaX, deleteModelSigmaY, loadAnalysisRecordKeys, loadHumidityCalibrant, loadModelSigmaX, loadSyncEnabled, saveAnalysisRecordKeys, saveHumidityCalibrant } from '../../../services/cache/localStorage';
import {
  apiGetRecord,
  apiGetRecordPartition,
  apiGetUserRecordKeys,
  apiPostRecord,
  apiPostRecordPartitions,
  apiGetRecordSensogramPartitionKeys,
  apiInitDOHSession,
  apiGetRecordHumidityPartitionKeys,
  apiGetRecordTemperaturePartitionKeys,
} from '../../../services/api/api';
import { useOktaAuth } from '@okta/okta-react';
import { ARYBALLE_COLOR_GRAY, colorPalette, useMediaQuery } from '../../../utils/helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDroplet } from '@fortawesome/free-solid-svg-icons';
import { computeDeltaSensor, computeSignature } from '../../../components/analysis/compute';

const uploadRecord = async (userID: string | null, recordKey: RecordKey) => {
  let _record = await getRecord(recordKey);
  if (_record == undefined) {
    return;
  }
  if (userID === null) {
    antdMessage.error('Failed to upload record: not logged in');
    return;
  }
  await apiPostRecord(_record, userID);
  await apiPostRecordPartitions(_record);
};

const computeAndSetHumidityCalibrant = async (recordKey: RecordKey) => {
  // get signal
  let record = await getFullRecord(recordKey);

  let _spotsgrid1d = record.device.spotsgrid;
  let _signature = computeSignature(record);
  let deltaHumidity = computeDeltaSensor(record, SENSOR_NATURE.Humidity);
  let deltaTemperature = computeDeltaSensor(record, SENSOR_NATURE.Temperature);

  saveHumidityCalibrant({
    recordKey: recordKey,
    signature: _signature,
    spotsgrid: _spotsgrid1d,
    deltaHumidity: deltaHumidity,
    deltaTemperature: deltaTemperature,
  });
};

export const OpenInDOHButton: FC<{
  userID: string | null;
  bearerToken: string | undefined;
  recordKeys: RecordKey[];
}> = ({ userID, bearerToken, recordKeys }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sessionID, setSessionID] = useState<string | null>(null);

  useEffect(() => {
    setSessionID(null);
  }, [recordKeys]);

  return (
    <>
      <Tooltip
        overlay={
          <>
            <p>
              Upload the selected records to the cloud and open them in the <b>Digital Olfaction Hub</b>
            </p>
          </>
        }
        overlayInnerStyle={{
          maxWidth: 400,
          textAlign: 'center',
        }}
      >
        <Button
          type={sessionID === null ? 'default' : 'primary'}
          block
          loading={isLoading}
          href={sessionID === null ? undefined : `https://hub.aryballe.com/dashboard/${sessionID}`}
          target="_blank"
          onClick={async () => {
            if (!bearerToken) {
              antdMessage.error('Error: not logged in!');
              return;
            }
            try {
              if (sessionID !== null) {
                return;
              }
              setIsLoading(true);
              for (let recordKey of recordKeys) {
                void (await uploadRecord(userID, recordKey));
              }
              let _sessionID = await apiInitDOHSession(recordKeys, bearerToken);
              setSessionID(_sessionID);
            } catch (e: any) {
              console.error(e);
              antdMessage.error('Error trying to open in DOH:' + e.message);
            } finally {
              setIsLoading(false);
            }
          }}
        >
          {sessionID === null ? (
            <>Upload to DOH</>
          ) : (
            <>
              Go to DOH: <b>{sessionID}</b>
            </>
          )}
        </Button>
      </Tooltip>
    </>
  );
};

const LOCAL_STORAGE_HUMDITY_CALIBRANT_KEY = 'humidityCalibrant';

export const RecordsManagement: FC = () => {
  const [records, setRecords] = useState<RecordValue[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<RecordKey[]>([]);
  const [analysisRecordKeys, setAnalysisRecordKeys] = useState<RecordKey[]>([]);
  const [filteredRecords, setFilteredRecords] = useState<RecordValue[]>([]);
  const [searchString, setSearchString] = useState<string | null>(null);
  const searchInputRef = useRef<InputRef>(null);
  const [recordHumitidyCalibrant, setRecordHumidityCalibrant] = useState<string>();

  const { authState, oktaAuth } = useOktaAuth();
  const [userID, setUserID] = useState<string | null>(null);
  const [syncEnabled, setSyncEnabled] = useState<boolean>(loadSyncEnabled());

  const isMobile = useMediaQuery();

  useEffect(() => {
    if (authState && authState.isAuthenticated && authState.idToken && authState.idToken.claims) {
      setUserID(authState.idToken.claims.sub);
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    getAllRecords().then((_records) => {
      _records.sort((a, b) => {
        return b.absoluteTimestamp - a.absoluteTimestamp;
      });
      setRecords(_records);
    });
  }, []);

  useEffect(() => {
    let humidityCalibrant = loadHumidityCalibrant();
    if (humidityCalibrant !== undefined) setRecordHumidityCalibrant(humidityCalibrant.recordKey);
  }, [window.localStorage.getItem(LOCAL_STORAGE_HUMDITY_CALIBRANT_KEY)]);

  useEffect(() => {
    let _analysisRecordKeys = loadAnalysisRecordKeys();
    if (_analysisRecordKeys === null || _analysisRecordKeys.length === 0) {
      return;
    }
    _analysisRecordKeys = _analysisRecordKeys.filter((key) => {
      return records.find((record) => record.key === key) !== undefined;
    });
    setSelectedRowKeys(_analysisRecordKeys);
    setAnalysisRecordKeys(_analysisRecordKeys);
    saveAnalysisRecordKeys(_analysisRecordKeys);
  }, [records]);

  useEffect(() => {
    if (records === null) {
      return;
    }

    if (searchString === null || searchString === '') {
      setFilteredRecords(records);
      return;
    }

    // console.log('searching for', searchString)

    const _includesSearchString = (a: string): boolean => {
      let aNorm = a
        .toLowerCase()
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '');
      let bNorm = searchString
        .toLowerCase()
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '');
      return aNorm.includes(bNorm);
    };

    let _filteredRecords: RecordValue[] = [];
    for (let record of records) {
      let name = record.name ? record.name : '';
      let description = record.description ? record.description : '';
      if (_includesSearchString(name)) {
        _filteredRecords.push(record);
        continue;
      }
      if (_includesSearchString(description)) {
        _filteredRecords.push(record);
        continue;
      }
    }
    // console.log('filtered records', _filteredRecords)
    setFilteredRecords(_filteredRecords);
  }, [records, searchString]);

  const downloadCloudRecords = async (userID: string) => {
    // console.log("not implemented")
    let remoteRecordKeys = await apiGetUserRecordKeys(userID);
    console.log('downloading records: remote record keys', remoteRecordKeys);
    let localRecordKeys = await getAllRecordKeys();
    console.log('downloading records: local record keys', localRecordKeys);
    if (remoteRecordKeys === null) {
      antdMessage.warning('No records in the Cloud!');
      return;
    }
    for (let remoteRecordKey of remoteRecordKeys) {
      if (localRecordKeys.includes(remoteRecordKey)) {
        continue;
      }
      try {
        let remoteRecordPartitionKeys = await apiGetRecordSensogramPartitionKeys(remoteRecordKey);
        let remoteRecord = await apiGetRecord(remoteRecordKey);
        for (let remotePartitionKey of remoteRecordPartitionKeys) {
          let remotePartition = await apiGetRecordPartition(remotePartitionKey);
          await putPartition(remotePartition);
        }

        let remoteRecordHumidityPartitionKeys = await apiGetRecordHumidityPartitionKeys(remoteRecordKey);
        // console.log("humidity partition keys", remoteRecordHumidityPartitionKeys)
        if (remoteRecordHumidityPartitionKeys) {
          for (let remoteHumidityPartitionKey of remoteRecordHumidityPartitionKeys) {
            let remotePartition = await apiGetRecordPartition(remoteHumidityPartitionKey);
            await putPartition(remotePartition);
          }
        }

        let remoteRecordTemperaturePartitionKeys = await apiGetRecordTemperaturePartitionKeys(remoteRecordKey);
        // console.log("temperature partition keys", remoteRecordTemperaturePartitionKeys)
        if (remoteRecordTemperaturePartitionKeys) {
          for (let remoteTemperaturePartitionKey of remoteRecordTemperaturePartitionKeys) {
            let remotePartition = await apiGetRecordPartition(remoteTemperaturePartitionKey);
            await putPartition(remotePartition);
          }
        }

        let localRecord: RecordValue = {
          key: remoteRecordKey,
          name: remoteRecord.Name,
          description: remoteRecord.Description,
          absoluteTimestamp: remoteRecord.AbsoluteTimestamp,

          device: {
            commonName: remoteRecord.DeviceCommonName,
            shellSerial: remoteRecord.DeviceShellSerial,
            coreSensorSerial: remoteRecord.DeviceCoreSensorSerial,
            fwVersion: remoteRecord.DeviceFwVersion,
            hwVersion: remoteRecord.DeviceHwVersion,
            cameraExposure: remoteRecord.DeviceCameraExposure,
            spotsgrid: remoteRecord.DeviceSpotsgrid,
          },

          baselineStart: remoteRecord.BaselineStart,
          baselineEnd: remoteRecord.BaselineEnd,
          analyteStart: remoteRecord.AnalyteStart,
          analyteEnd: remoteRecord.AnalyteEnd,

          sensogramNFrames: remoteRecord.SensogramNFrames,
          sensogramPartitionKeys: remoteRecordPartitionKeys,
          humidityPartitionKeys: remoteRecordHumidityPartitionKeys && remoteRecordHumidityPartitionKeys.length > 0 ? remoteRecordHumidityPartitionKeys : undefined,
          temperaturePartitionKeys: remoteRecordTemperaturePartitionKeys && remoteRecordTemperaturePartitionKeys.length > 0 ? remoteRecordTemperaturePartitionKeys : undefined,
        };
        await putRecord(localRecord);
        setRecords(await getAllRecords());
        antdMessage.success(`Successfully downloaded record "${localRecord.name}"`);
      } catch (e: any) {
        console.log('failed to download record', remoteRecordKey, e.message);
      }
    }
    antdMessage.success(`Successfully downloaded ${remoteRecordKeys.length} record${remoteRecordKeys.length > 1 ? 's' : ''} out of ${remoteRecordKeys.length}`);
  };

  let columns: TableColumnType<RecordValue>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text: string, record: RecordValue) => {
        if (!text) {
          text = '—';
        }
        return (
          <Typography.Text
          // editable={{
          //     async onChange(value) {
          //         await updateRecordName(record.key, value)
          //         let _records = [...records]
          //         _records.find((r) => r.key === record.key)!.name = value
          //         setRecords(_records)
          //     },
          //     autoSize: true,
          //     tooltip: 'Click to edit name',
          // }}
          >
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchString ? searchString : '']}
              autoEscape
              sanitize={(text) => {
                return text
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/\p{Diacritic}/gu, '');
              }}
              textToHighlight={record.name ? record.name : ''}
            />
          </Typography.Text>
        );
      },
      ellipsis: true,
    },
    {
      title: 'Descr.',
      dataIndex: 'description',
      render: (text: string, record: RecordValue) => {
        if (text === '') {
          text = '—';
        }
        return (
          <Typography.Text>
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchString ? searchString : '']}
              autoEscape
              sanitize={(text) => {
                return text
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/\p{Diacritic}/gu, '');
              }}
              textToHighlight={record.description ? record.description : ''}
            />
          </Typography.Text>
        );
      },
      ellipsis: true,
    },
    {
      title: 'Date/Time',
      render: (record: RecordValue) => {
        return new Date(record.absoluteTimestamp).toLocaleString();
      },
      sortDirections: ['descend', 'ascend'],
      sorter: (a: RecordValue, b: RecordValue) => {
        return a.absoluteTimestamp - b.absoluteTimestamp;
      },
    },
    {
      title: 'Nb Frames',
      render: (record: RecordValue) => {
        return record.sensogramNFrames;
      },
    },
    {
      title: 'Actions',
      fixed: 'right',
      render: (record: RecordValue) => {
        return (
          <FlexRow style={{ width: '100%' }}>
            <Link to={`/records/${record.key}`}>
              <Button type="primary" icon={<EyeOutlined />} />
            </Link>
            {recordHumitidyCalibrant === record.key ? (
              <Button type="text" icon={<FontAwesomeIcon icon={faDroplet} style={{ fontSize: '13pt', color: colorPalette.blue }} />} />
            ) : (
              <Button
                type="text"
                icon={<FontAwesomeIcon icon={faDroplet} style={{ fontSize: '13pt', color: '#c2c2c2' }} />}
                onClick={async () => {
                  try {
                    await computeAndSetHumidityCalibrant(record.key);
                    setRecordHumidityCalibrant(record.key);
                    antdMessage.success(`Successfully saved "${record.name}" as humidity calibrant`);
                  } catch (e: any) {
                    console.error(e);
                    antdMessage.error('Failed to save humidity calibrant: ' + e.message);
                  }
                }}
              />
            )}
            {userID && syncEnabled && (
              <Button
                type="text"
                icon={<CloudUploadOutlined />}
                onClick={async () => {
                  try {
                    await uploadRecord(userID, record.key);
                    antdMessage.success(`Successfully uploaded record "${record.name}"`);
                  } catch (e: any) {
                    console.error(e);
                    antdMessage.error('Failed to upload record: ' + e.message);
                  }
                }}
              />
            )}
            <Popconfirm
              title="Are you sure you want to delete this record?"
              onConfirm={async () => {
                deleteRecord(record.key);
                setRecords(await getAllRecords());
              }}
            >
              <Button type="text" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </FlexRow>
        );
      },
      width: 120,
    },
  ];

  if (isMobile) {
    columns = columns.filter((column) => {
      return column.title !== 'Date/Time' && column.title !== 'Nb Frames';
    });
  }

  return (
    <FlexCol>
      {/* imput */}
      <Paper>
        <FlexRow>
          <Input
            ref={searchInputRef}
            tabIndex={-1}
            autoFocus
            allowClear
            placeholder={`Search using a keyword..`}
            onPressEnter={() => {
              if (!searchInputRef.current || !searchInputRef.current.input) {
                setSearchString(null);
                return;
              }
              setSearchString(searchInputRef.current.input.value);
            }}
            onChange={() => {
              if (!searchInputRef.current || !searchInputRef.current.input || searchInputRef.current.input.value === '') {
                setSearchString(null);
                return;
              }
              if (records.length < 1000) {
                setSearchString(searchInputRef.current.input.value);
              }
            }}
            style={{ marginBottom: 8 }}
          />
          <Button
            type="primary"
            onClick={() => {
              if (!searchInputRef.current || !searchInputRef.current.input) {
                setSearchString(null);
                return;
              }
              setSearchString(searchInputRef.current.input.value);
            }}
            icon={<SearchOutlined />}
          >
            Filter
          </Button>
        </FlexRow>
      </Paper>
      {/* table */}
      <Paper>
        <FlexCol
          style={{
            width: '100%',
          }}
        >
          <Table
            size="small"
            columns={columns}
            dataSource={filteredRecords}
            rowKey={(record) => record.key}
            onRow={(record) => {
              return {
                onClick: () => <Redirect push to={record.key} />,
              };
            }}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedRowKeys,
              onChange: (selectedRowKeys) => {
                setSelectedRowKeys(selectedRowKeys as RecordKey[]);
              },
              preserveSelectedRowKeys: false,
            }}
            style={{
              width: '100%',
            }}
            pagination={{
              pageSizeOptions: (function () {
                let sizes = [];
                if (records.length < 10) {
                  sizes.push(records.length);
                  return sizes;
                }
                sizes.push(10);
                if (records.length < 20) {
                  sizes.push(records.length);
                  return sizes;
                }
                sizes.push(20);
                if (records.length < 50) {
                  sizes.push(records.length);
                  return sizes;
                }
                sizes.push(50);
                if (records.length < 100) {
                  sizes.push(records.length);
                  return sizes;
                }
                sizes.push(100);
                sizes.push(records.length);
              })(),
              showSizeChanger: true,
            }}
          />
          <Row justify="end" gutter={[10, 10]}>
            {userID && syncEnabled && (
              <Col>
                <Tooltip overlay={<>Download records from the cloud</>}>
                  <Button
                    icon={<CloudDownloadOutlined />}
                    onClick={async () => {
                      void (await downloadCloudRecords(userID));
                    }}
                  />
                </Tooltip>
              </Col>
            )}
            {selectedRowKeys.length > 0 && (
              <>
                <Col>
                  <OpenInDOHButton userID={userID} bearerToken={authState?.accessToken?.accessToken} recordKeys={selectedRowKeys} />
                </Col>
                <Col>
                  <Button
                    type="primary"
                    block
                    disabled={selectedRowKeys.length < 2}
                    onClick={() => {
                      setAnalysisRecordKeys(selectedRowKeys);
                      saveAnalysisRecordKeys(selectedRowKeys);
                      deleteModelSigmaX();
                      deleteModelSigmaY();
                    }}
                  >
                    Analyze {selectedRowKeys.length} record{selectedRowKeys.length > 1 ? 's' : ''}
                  </Button>
                </Col>
                {analysisRecordKeys.length > 1 && (
                  <Col>
                    <Button
                      onClick={() => {
                        setAnalysisRecordKeys([]);
                      }}
                      icon={<CloseOutlined />}
                    >
                      Clear analysis
                    </Button>
                  </Col>
                )}
                <Col>
                  {userID && syncEnabled && (
                    <Tooltip overlay={<>Upload selected records to the cloud</>}>
                      <Button
                        icon={<CloudUploadOutlined />}
                        onClick={async () => {
                          let i = 0;
                          for (let recordKey of selectedRowKeys) {
                            try {
                              await uploadRecord(userID, recordKey);
                              i++;
                            } catch (e: any) {
                              console.log('failed to upload record', recordKey, e.message);
                            }
                          }
                          antdMessage.success(`Successfully uploaded ${i} record${selectedRowKeys.length > 1 ? 's' : ''} out of ${selectedRowKeys.length}`);
                        }}
                      />
                    </Tooltip>
                  )}
                </Col>
                <Col>
                  <Tooltip overlay={<>Unselect all (all pages)</>}>
                    <Button
                      icon={<MinusSquareOutlined />}
                      onClick={() => {
                        setSelectedRowKeys([]);
                        setAnalysisRecordKeys([]);
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip overlay={<>Select all (all pages)</>}>
                    <Button
                      icon={<PlusSquareOutlined />}
                      onClick={() => {
                        setSelectedRowKeys(records.map((record) => record.key));
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Popconfirm
                    title="Are you sure you want to delete selected records?"
                    onConfirm={async () => {
                      for (let key of selectedRowKeys) {
                        await deleteRecord(key);
                      }
                      setRecords(
                        records.filter((record) => {
                          return !selectedRowKeys.includes(record.key);
                        })
                      );
                      setSelectedRowKeys([]);
                    }}
                  >
                    <Button danger type="primary" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Col>
              </>
            )}
          </Row>
        </FlexCol>
      </Paper>
      {analysisRecordKeys.length > 1 && <RecordsAnalysisWidget recordKeys={analysisRecordKeys} recordNames={records.map((r) => (r.name ? r.name : ''))} />}
    </FlexCol>
  );
};
