import { useEffect, useRef, useState } from 'react';

import { Col, Flex, Row } from 'antd';
import { Progress } from 'antd';
import { Button, Switch } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import { DeviceValue, RecordKey } from '../services/cache/idb';
import { Mutex, MutexInterface, withTimeout } from 'async-mutex';
import { CSM_PROTOCOL_COMMAND_TYPE, CSM_PROTOCOL_EVENT_TYPE, parseBiosensorsSignalEvent, parseEventPayload } from '../components/serial/csm';
import { v4 as uuidv4 } from 'uuid';
import { loadGenericModel, loadHumidityCalibrant, loadSpotsGrid1D } from '../services/cache/localStorage';
import { DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC, DEFAULT_PLOT_DECIMATED_FPS, DEFAULT_STORAGE_DECIMATED_FPS, IDB_PARTITION_WINDOW_SIZE, PLOT_WINDOW_SIZE } from '../utils/constants/constants';
import { aggregateSignature, correctHumidity, normalizeL2, parseMessagePayload, processMziData, shouldCompute, sortSignature, updateKineticSeries } from '../components/analysis/compute';
import { SignatureWithSpotgrid } from '../types/types';
import { mean, standardDeviation, transpose } from '../components/analysis/utils';
import { QuestioningState } from './OdorIdentificationPage/NoaOdorIdentificationContainer';
import { DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE } from '../components/serial/constants';
import { GenericModel, ModelCategory } from '../components/analysis/definitions';
import { Paper } from '../components/common/common';
import { IntensityGauge } from '../components/widgets/Gauges/IntensityGauge';
import { HumidityGauge } from '../components/widgets/Gauges/HumidityGauge';
import { useMessageContext } from '../state/context/MessageContext';

type BleOdorIdentificationProps = {};

enum SenseMode {
  Recording,
  Questionning,
}

const Monitoring: React.FC<BleOdorIdentificationProps> = () => {
  const { csmMessages, csmIsConnected, consumeCSMMessage, clearCSMMessages, addCSMCommand, hihValues } = useMessageContext();

  // * gauge tooling
  const MIN_VALUE = -7; // Define minimum value for the gauge
  const MAX_VALUE = 7; // Define maximum value for the gauge
  const mapValueToPercent = (value: number): number => {
    // Map the value (-100 to 100) to a percentage (0 to 100)
    return ((value - MIN_VALUE) / (MAX_VALUE - MIN_VALUE)) * 100;
  };
  // *

  // * MZI and timestamps related useRefs
  const firstMZIsRef = useRef<number[] | null>(null);
  const previousMZIsRef = useRef<number[] | null>(null);
  const signalEnvelopeAvgRef = useRef<number>(0);
  const rawMZISeriesRef = useRef<number[][]>([]);
  const averageMZISeriesRef = useRef<number[]>([]);

  const rawTimestampSeriesRef = useRef<number[]>([]);
  const lastDecimationTickRef = useRef<number>(0);
  const noizeLevelRef = useRef<number>(0);

  // * device related useState
  const [deviceValue, setDeviceValue] = useState<DeviceValue | null>(null);
  const [currentSpotsgrid1d, setCurrentSpotsgrid1d] = useState<number[] | null>(null);

  // * humidity compensation
  const [humidityCalibrant, setHumidityCalibrant] = useState<SignatureWithSpotgrid | undefined>(undefined);
  const humidityBaselineRef = useRef<number | null>(null);
  const [humidityCompensationEnabled, sethumidityCompensationEnabled] = useState<boolean>(true);
  const humidityDisplayed = useRef<number>(0);
  const temperatureDisplayed = useRef<number>(0);

  // * Algo tooling  for later
  const NOISE_MULTIPLYING_FACTOR = 30;
  const isOdorPresentRef = useRef<boolean>(false);
  const isMZIcomputingrequested = useRef<boolean>(false);
  const odorPresenceThresholdLevelRef = useRef<number>(0);
  const [questioningState, setQuestioningState] = useState<QuestioningState>(QuestioningState.BaselineRecording);
  const questionState = useRef<QuestioningState>(QuestioningState.BaselineRecording);
  const maxOdorPresentValue = useRef<number>(0);
  const decimatedMZISeriesCorrected = useRef<number[][]>([]);
  const [questionningSignature, setQuestionningSignature] = useState<number[] | null>(null);
  const [questionningSpotsgrid1d, setQuestionningSpotsgrid1d] = useState<number[] | null>(null);

  const [currentModel, setCurrentModel] = useState<GenericModel | null>(null);

  // *
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // mutex
  const messageQueueMutexRef = useRef<MutexInterface>(withTimeout(new Mutex(), 300));

  // start sampling when mounting the component
  useEffect(() => {
    clearCSMMessages();
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.StartSampling,
      },
    });
    setIsLoading(false);
    return () => {
      addCSMCommand({
        id: uuidv4().toString(),
        message: {
          CmdType: CSM_PROTOCOL_COMMAND_TYPE.StopSampling,
        },
      });
      setIsLoading(true);
    };
  }, []);

  // load humidity calibrant
  useEffect(() => {
    const hCalibrant = loadHumidityCalibrant();
    setHumidityCalibrant(hCalibrant);
    console.log('humidityCalibrant is', hCalibrant);
  }, []);

  // load spotgrid from local storage
  useEffect(() => {
    let _spotsgrid1d = loadSpotsGrid1D();
    if (!_spotsgrid1d) {
      console.log('sense page: spotsgrid1d is empty');
      return;
    }
    setCurrentSpotsgrid1d(_spotsgrid1d);
  }, []);

  // algo: load model when analysis state
  useEffect(() => {
    if (questionState.current !== QuestioningState.OdorAnalysis) return;
    let _model = loadGenericModel();
    // let _model = loadModel();
    setCurrentModel(_model);
  }, []);

  // watch csm messages
  useEffect(() => {
    if (csmMessages.length === 0 || messageQueueMutexRef.current.isLocked()) {
      return;
    }
    messageQueueMutexRef.current
      .acquire()
      .then((release) => {
        let nFramesProcessed = 0;
        csmMessages.forEach((message) => {
          if (message.message.Type !== CSM_PROTOCOL_EVENT_TYPE.BiosensorsSignalEvent) {
            console.log('Non-biosensor event:', message.message);
            consumeCSMMessage(message.id);
            return;
          } else {
            nFramesProcessed++;
            consumeCSMMessage(message.id);
            // Parse message payload
            let mzis = parseMessagePayload(message);
            if (!mzis) return;

            // set Baseline delta H for future use
            if (humidityBaselineRef.current === null) {
              console.log('Changing humidity baseline reference as ', hihValues.humidity);
              humidityBaselineRef.current = hihValues.humidity;
            }
            // set ref
            if (firstMZIsRef.current === null) {
              firstMZIsRef.current = [...mzis];
            }

            isMZIcomputingrequested.current = shouldCompute(message.ts, lastDecimationTickRef); // to prevent frequent rendering
            let [averageMZI, correctedMZI] = processMziData(mzis, rawMZISeriesRef.current, firstMZIsRef.current, currentSpotsgrid1d, humidityCompensationEnabled, humidityCalibrant, humidityBaselineRef.current, hihValues) || [0, []];
            updateKineticSeries(averageMZI, correctedMZI, averageMZISeriesRef, decimatedMZISeriesCorrected);
            //console.log('kinetic average is', averageMZISeriesRef)
            if (isMZIcomputingrequested.current) {
              // console.log('averageMZI...', averageMZI, ' humidity correction is', humidityCompensationEnabled, 'lastDecimationTickRef.current is', lastDecimationTickRef.current)

              // Update displayed values
              signalEnvelopeAvgRef.current = averageMZI; // for display in the component
              console.log(signalEnvelopeAvgRef.current);
              humidityDisplayed.current = hihValues.humidity;
              temperatureDisplayed.current = hihValues.temperature;
              //updateOdorPresenceAndQuestioningState(mode, averageMZI, averageMZISeriesRef.current, decimatedMZISeriesCorrected.current, isOdorPresentRef, questionState, maxOdorPresentValue, odorPresenceThresholdLevelRef)
            }
            //console.log('averageMZISeriesRef...', averageMZISeriesRef.current, '.. humidity correction is', humidityCompensationEnabled)
            // for later
            if (questionState.current === QuestioningState.OdorAnalysis) {
              console.log('computing signature...');
              constructSignatureAndRecognize();
            }
          }
        });
        rawMZISeriesRef.current = []; // do we need this reset in monitoring mode ?
        // Release the mutex after processing
        release();
      })
      .catch((e) => {
        console.error('Mutex acquisition failed', e);
        messageQueueMutexRef.current.cancel();
        messageQueueMutexRef.current.release();
      });

    return () => {
      messageQueueMutexRef.current.cancel();
      messageQueueMutexRef.current.release();
    };
  }, [csmMessages]);

  useEffect(() => {
    const constructDeviceValue = async () => {
      let commonName = 'Neose CSM BLE';
      if (commonName === undefined || commonName === null) {
        commonName = '';
      }
      let shellSerial = '';
      let coreSensorSerial = '';
      let fwVersion = '';
      let hwVersion = '';
      let cameraExposure = 0;

      let spotsgrid = currentSpotsgrid1d;
      if (spotsgrid === undefined || spotsgrid === null) {
        throw new Error('spotsgrid is undefined');
      }

      let _deviceValue = {
        commonName,
        shellSerial,
        coreSensorSerial,
        fwVersion,
        hwVersion,
        cameraExposure,
        spotsgrid,
      };
      console.log('sense page: constructed device value', _deviceValue);
      return _deviceValue;
    };
    constructDeviceValue()
      .then((_deviceValue) => {
        setDeviceValue(_deviceValue);
      })
      .catch((e: any) => {
        console.log('sense page: could not construct device', e);
      });
  }, [currentSpotsgrid1d]);

  // reset MZI for display centered around 0
  const onClickReset = () => {
    // previousMZIsRef.current = null is to avoid synthetic phase jumps when signal drops from some value > pi/2 to zero
    previousMZIsRef.current = null;
    firstMZIsRef.current = null;
    humidityBaselineRef.current = null;
    averageMZISeriesRef.current = [];
  };

  // for later
  const constructSignatureAndRecognize = (idxStart?: number) => {
    if (idxStart === undefined) {
      idxStart = -DEFAULT_PLOT_DECIMATED_FPS * DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC;
    }
    let sectionMZIs = decimatedMZISeriesCorrected.current.slice(idxStart);
    let sectionMZIsSpans = transpose(sectionMZIs);

    if (!currentSpotsgrid1d) {
      console.log('sense page: spotsgrid is empty');
      return;
    }

    // signature with no baseline substraction, simple analyte mean
    let _signature = sectionMZIsSpans.map((mzis) => mean(mzis));
    let excludedSignature: number[] = [];
    let excludedSpotsgrid1d: number[] = [];
    for (let i = 0; i < currentSpotsgrid1d.length; i++) {
      let sensorInt = currentSpotsgrid1d[i];
      // remove peptide 1 for computing
      if (sensorInt >= 1) {
        excludedSignature.push(_signature[i]);
        excludedSpotsgrid1d.push(sensorInt);
      }
    }

    let finalSignature: number[] = [];
    let finalSpotsgrid1d: number[] = [];

    // always aggregate by common spot name
    let [aggregatedSignature, aggregatedSpotsgrid1d] = aggregateSignature(excludedSignature, excludedSpotsgrid1d);
    finalSignature = aggregatedSignature;
    finalSpotsgrid1d = aggregatedSpotsgrid1d;

    let [sortedFinaleSignature, sortedFinalSpotsgrid1d] = sortSignature(finalSpotsgrid1d, finalSignature);

    // disable normallisation when comparing intensities
    if (currentModel?.metadata?.type === ModelCategory.ComparisonIntensities) {
      setQuestionningSignature(sortedFinaleSignature);
      setQuestionningSpotsgrid1d(sortedFinalSpotsgrid1d);
    } else {
      let normalizedSortedAggregatedSignature = normalizeL2(sortedFinaleSignature);
      setQuestionningSignature(normalizedSortedAggregatedSignature);
      setQuestionningSpotsgrid1d(sortedFinalSpotsgrid1d);
    }
  };

  return (
    <Paper>
      <Row align="top" gutter={5} style={{ marginTop: 50, marginBottom: 50 }}>
        <Col span={24} md={12}>
          <Row justify="end">
            <Button type="primary" icon={<ReloadOutlined />} onClick={onClickReset} />
          </Row>
          <IntensityGauge MZIvalue={parseFloat(signalEnvelopeAvgRef.current.toFixed(1))} hihValues={{ humidity: humidityDisplayed.current, temperature: temperatureDisplayed.current }} />
          <Flex justify="center" align="middle" gap="small" style={{ textAlign: 'center' }}>
            <p style={{ marginBottom: 0, fontSize: 16 }}>Humidity correction enabled</p>
            <Switch onChange={sethumidityCompensationEnabled} defaultChecked={humidityCompensationEnabled} />
          </Flex>
        </Col>
        <Col span={24} md={12} style={{ marginTop: 32 }}>
          <HumidityGauge humidityValue={parseFloat(humidityDisplayed.current.toFixed(1))} />
        </Col>
      </Row>
    </Paper>
  );
};

export default Monitoring;
