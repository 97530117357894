import { theme } from 'antd';

export const themeConfig = {
  algorithm: theme.defaultAlgorithm,
  token: {
    colorPrimary: 'rgb(32, 42, 68)',
    colorLink: 'rgb(32, 42, 68)',
    fontFamily: 'Century Gothic',
    fontSize: 13,
    colorTextBase: '#222',
    colorPrimaryBg: 'rgba(32, 42, 68, 0.2)',
    colorPrimaryBgHover: 'rgba(32, 42, 68, 0.4)',
  },
};
