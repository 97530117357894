import { ControlOutlined, ExperimentOutlined, FolderOpenOutlined, FundViewOutlined, SearchOutlined } from '@ant-design/icons';
import { Card, Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { CardLink, FlexCol } from '../components/common/common';
import { FC } from 'react';
import { isBleApp, useMediaQuery } from '../utils/helpers/utils';
import { CHEMICAL_FAMILY_IDENTIFICATION_ROUTE, DEVICE_ROUTE, MODELS_MANAGEMENT_ROUTE, MONITORING_ROUTE, ODOR_IDENTIFICATION_ROUTE, SENSE_ROUTE } from '../config/routes';

export const HomePage: FC = () => {
  const isMobile = useMediaQuery();
  const fontSize = 40;

  return (
    <FlexCol
      style={{
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Row>
        <FlexCol
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography.Title
            level={2}
            style={{
              fontSize,
              marginBottom: 0,
              textAlign: 'center',
            }}
          >
            Welcome to NeOse WebApp
          </Typography.Title>
          <Typography.Text
            style={{
              fontSize: 20,
              textAlign: 'center',
            }}
          >
            {isBleApp() ? (
              <>
                Make sure to turn on <span style={{ color: '#0082FC' }}>Bluetooth</span> on your {isMobile ? 'smartphone' : 'computer'} and let's goooooo
              </>
            ) : (
              <>Make sure to connect your NeOse device to your computer and let's goooooo</>
            )}
          </Typography.Text>
        </FlexCol>
      </Row>
      <Row gutter={[10, 10]} align="stretch">
        <Col xs={24} md={8}>
          <CardLink to={DEVICE_ROUTE.path} title="Manage my NeOse" icon={<ControlOutlined style={{ fontSize }} />} />
        </Col>
        <Col xs={24} md={8}>
          <CardLink to={SENSE_ROUTE.path} title="RealTime Olfactive Sensing" icon={<FundViewOutlined style={{ fontSize }} />} />
        </Col>
        <Col xs={24} md={8}>
          <CardLink to={MODELS_MANAGEMENT_ROUTE.path} title="Manage my Records / Models" icon={<FolderOpenOutlined style={{ fontSize }} />} />
        </Col>
        <Col xs={24} md={8}>
          <CardLink to={ODOR_IDENTIFICATION_ROUTE.path} title="Odor/VOC identification" icon={<FundViewOutlined style={{ fontSize }} />} />
        </Col>
        <Col xs={24} md={8}>
          <CardLink to={CHEMICAL_FAMILY_IDENTIFICATION_ROUTE.path} title="Chemical family identification" icon={<ExperimentOutlined style={{ fontSize }} />} />
        </Col>
        <Col xs={24} md={8}>
          <CardLink to={MONITORING_ROUTE.path} title="VOC Intensity Monitoring" icon={<SearchOutlined style={{ fontSize }} />} />
        </Col>
      </Row>
    </FlexCol>
  );
};
