import { Col, Row } from 'antd';
import SectionPage from '../../../components/widgets/section/SectionPage';
import { HIHvalues } from '../../../types/types';
import { IntensityGauge } from '../../../components/widgets/Gauges/IntensityGauge';

interface SensorCleaningProps {
  MZIvalue: number;
  hihValues?: HIHvalues;
}

const SensorCleaning: React.FC<SensorCleaningProps> = ({ MZIvalue, hihValues }) => {
  return (
    <SectionPage>
      <Col span={24}>
        <IntensityGauge MZIvalue={MZIvalue} hihValues={hihValues} />

        <Row justify="center">
          <Col span={20}>
            <h1 style={{ textAlign: 'center', fontSize: 20, lineHeight: '30px', marginTop: 20, marginBottom: 20 }}>Sensor not ready</h1>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={20} style={{ borderRadius: 20, textAlign: 'center' }}>
            <p style={{ fontSize: 16, lineHeight: '22px' }}>Please make sure there is no odor near the sensor.</p>
          </Col>
        </Row>
      </Col>
    </SectionPage>
  );
};

export default SensorCleaning;
