import { Button, Col, Row, Table, TableColumnType, Tag, Tooltip, message as antdMessage } from 'antd';
import { FlexCol, FlexRow, Paper } from '../../components/common/common';
import { useEffect, useState } from 'react';
import { getAllModels } from '../../services/cache/idb';
import { GenericModel } from '../../components/analysis/definitions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faDroplet, faFileCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { loadGenericModel, saveGenericModel } from '../../services/cache/localStorage';
import { useMediaQuery } from '../../utils/helpers/utils';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { fetchModel, fetchModelList } from '../../components/analysis/models';

type ModelsPageProps = {};

const LOCAL_STORAGE_CURRENT_GENERIC_MODEL_KEY = 'my_generic_model';

export const ModelsTable: React.FC<ModelsPageProps> = () => {
  const [models, setModels] = useState<GenericModel[]>();
  const [currentModel, setCurrentModel] = useState<GenericModel>();
  const isMobile = useMediaQuery();

  const downloadModels = async () => {
    let modelNameList = await fetchModelList();
    for (let modelName of modelNameList) {
      await fetchModel(modelName);
    }
    let allModels = await getAllModels();
    setModels(allModels);
  };

  useEffect(() => {
    getAllModels().then((models) => {
      setModels(models);
    });
  }, []);

  useEffect(() => {
    let _model = loadGenericModel();
    if (_model !== null) setCurrentModel(_model);
  }, [window.localStorage.getItem(LOCAL_STORAGE_CURRENT_GENERIC_MODEL_KEY)]);

  let columns: TableColumnType<any>[] = [
    {
      title: 'Name',
      render: (model: GenericModel) => {
        return model?.metadata.ID.charAt(0).toUpperCase() + model?.metadata.ID.slice(1);
      },
    },
    {
      title: 'Date/Time',
      render: (model: GenericModel) => {
        return new Date(model?.metadata.created_at).toLocaleString();
      },
      sortDirections: ['descend', 'ascend'],
      sorter: (a: GenericModel, b: GenericModel) => {
        return new Date(a.metadata.created_at).getTime() - new Date(b.metadata.created_at).getTime();
      },
      defaultSortOrder: 'descend',
    },
    {
      title: 'Comparison threshold',
      dataIndex: 'comparisonThreshold',
    },
    {
      title: 'Spotfile',
      render: (model: GenericModel) => {
        return model?.metadata.spotfile.map((s) => s + ', ');
      },
    },
    {
      title: 'Type',
      render: (model: GenericModel) => {
        return <Tag>{model?.metadata.type}</Tag>;
      },
    },
    {
      title: 'Nb reference',
      render: (model: GenericModel) => {
        return model?.referencesSignatures ? model?.referencesSignatures?.length : model?.referenceIntensities?.length;
      },
    },
    {
      title: 'Apply',
      fixed: 'right',
      render: (model: GenericModel) => {
        return (
          <FlexRow style={{ width: '100%' }}>
            {currentModel && currentModel.metadata?.ID === model.metadata.ID ? (
              <Button type="primary" icon={<FontAwesomeIcon icon={faCheck} style={{ fontSize: '13pt' }} />} />
            ) : (
              <Button
                icon={<FontAwesomeIcon icon={faFileCircleCheck} style={{ fontSize: '13pt' }} />}
                onClick={async () => {
                  try {
                    if (model) {
                      saveGenericModel(model);
                      setCurrentModel(model);
                    }
                  } catch (e: any) {
                    antdMessage.error(`Error while loading model: ${e.message}`);
                  }
                }}
              />
            )}
            {/* <Popconfirm
              title="Are you sure you want to delete this record?"
              onConfirm={async () => {
                deleteRecord(record.key);
                setRecords(await getAllRecords());
              }}
            >
              <Button type="text" danger icon={<DeleteOutlined />} />
            </Popconfirm> */}
          </FlexRow>
        );
      },
      width: 120,
    },
  ];

  if (isMobile) {
    columns = columns.filter((column) => {
      return column.title === 'Date/Time' || column.title === 'Name' || column.title === 'Apply';
    });
  }

  return (
    <FlexCol
      style={{
        width: '100%',
      }}
    >
      <Paper>
        <FlexCol
          style={{
            width: '100%',
          }}
        >
          <Table
            size="small"
            columns={columns}
            dataSource={models}
            rowKey={(record) => record.key}
            // rowSelection={{
            //   type: 'checkbox',
            //   selectedRowKeys: selectedRowKeys,
            //   onChange: (selectedRowKeys) => {
            //     setSelectedRowKeys(selectedRowKeys as RecordKey[]);
            //   },
            //   preserveSelectedRowKeys: false,
            // }}
            style={{
              width: '100%',
            }}
          />
          <Row justify="end" gutter={[10, 10]}>
            <Col>
              <Tooltip overlay={<>Download models from the cloud</>}>
                <Button
                  icon={<CloudDownloadOutlined />}
                  onClick={async () => {
                    void (await downloadModels());
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
        </FlexCol>
      </Paper>
    </FlexCol>
  );
};
