import { useHistory } from 'react-router-dom';
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { oidcConfig } from '../config/auth';

export const useAuth = () => {
  const history = useHistory();
  const oktaAuth = new OktaAuth(oidcConfig);

  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || '', window.location.origin));
  };

  return { oktaAuth, restoreOriginalUri };
};
