import { Action, State } from '../types/MetadataTypes';

export const initialState: State = {};

export function metadataReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_THIMPHU_PLATFORM_ID':
      return {
        ...state,
        thimphuPlatformId: action.payload,
      };
    case 'SET_THIMPHU_FSP_METADATA':
      return {
        ...state,
        thimphuFspMetadata: action.payload,
      };
    case 'SET_THIMPHU_MEM_INFOS':
      return {
        ...state,
        thimphuMemInfos: action.payload,
      };
    case 'SET_REFKIT_VERSION':
      return {
        ...state,
        refkitVersion: action.payload,
      };
    case 'SET_REFKIT_CONFIG':
      return {
        ...state,
        refkitConfig: action.payload,
      };
    case 'SET_REFKIT_CAPABILITIES':
      return {
        ...state,
        refkitCapabilities: action.payload,
      };
    case 'SET_REFKIT_MEM_INFOS_IMAGE':
      return {
        ...state,
        refkitMemInfosImage: action.payload,
      };
    case 'SET_REFKIT_MEM_INFOS_CORE_SENSOR':
      return {
        ...state,
        refkitMemInfosCoreSensor: action.payload,
      };
    default:
      return state;
  }
}
