export const getAPIBaseURL = () => {
  if (window.location.hostname === 'localhost') {
    // return 'https://webapp.aryballe.com/api'
    return 'http://localhost:8038';
  }
  return 'https://prod.api.webapp.aryballe.com';
};

enum ApiResponseType {
  JSON = 'json',
  ArrayBuffer = 'arraybuffer',
}

export interface ApiErrorType {
  Reason: string;
}
