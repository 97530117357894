import { useState } from 'react';
import './App.css';
import 'antd/dist/reset.css';
import 'react-json-view-lite/dist/index.css';
import { ConfigProvider, Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Security } from '@okta/okta-react';
import { useMediaQuery } from './utils/helpers/utils';
import { AppMenu } from './components/layout/AppMenu';
import { AppHeader } from './components/layout/AppHeader';
import { AppFooter } from './components/layout/AppFooter';
import { themeConfig } from './config/themeConfig';
import { useAuth } from './hook/useAuth';
import { useDisplayMode } from './hook/useDisplayMode';
import { AppContent } from './components/layout/AppContent';

const SIDER_WIDTH = 250;
const SIDER_COLLAPSED_WIDTH = 80;

function App() {
  const isMobile = useMediaQuery();
  const { oktaAuth, restoreOriginalUri } = useAuth();
  const [isCollapsed, setIsCollapsed] = useState(true);
  useDisplayMode();

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ConfigProvider theme={themeConfig}>
        <Layout>
          <AppMenu isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
          <Layout className="site-layout" style={{ marginLeft: isMobile ? 0 : isCollapsed ? SIDER_COLLAPSED_WIDTH : SIDER_WIDTH }}>
            <AppHeader />
            <AppContent />
            <AppFooter />
          </Layout>
        </Layout>
        <ToastContainer />
      </ConfigProvider>
    </Security>
  );
}

export default App;
