import Sider from 'antd/es/layout/Sider';
import { FC } from 'react';
import { isBleApp, useMediaQuery } from '../../utils/helpers/utils';
import { FlexCol, FlexRow } from '../common/common';
import { Menu } from 'antd';
import { ControlOutlined, FolderOpenOutlined, FundViewOutlined, HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { CSMBleMessageDispatcher } from '../../state/dispatchers/CsmBleMessageDispatcher';
import { NoaRefkitMessageDispatcher } from '../../state/dispatchers/NoaRefkitMessageDispatcher';
import { NoaThimphuMessageDispatcher } from '../../state/dispatchers/NoaThimphuMessageDispatcher';
import { routes } from '../../config/routes';

const SIDER_WIDTH = 250;
const SIDER_COLLAPSED_WIDTH = 80;

export const AppMenu: FC<{
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
}> = ({ isCollapsed, setIsCollapsed }) => {
  const isMobile = useMediaQuery();

  return (
    <Sider
      style={
        isMobile
          ? {
              overflow: 'auto',
              position: 'fixed',
              width: '100%',
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1000,
            }
          : {
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              left: 0,
              top: 0,
              bottom: 0,
            }
      }
      collapsible={isMobile ? false : true}
      collapsed={isCollapsed}
      onCollapse={(collapsed) => {
        setIsCollapsed(collapsed);
      }}
      collapsedWidth={isMobile ? '100%' : undefined}
      width={isMobile ? '100%' : SIDER_WIDTH}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          gap: 0,
        }}
      >
        {!isMobile && (
          <FlexCol
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              gap: 0,
            }}
          >
            <FlexRow
              style={{
                height: '64px',
                justifyContent: 'center',
              }}
            >
              {isCollapsed ? (
                <img
                  alt="logo"
                  src="/images/logo292.png"
                  style={{
                    padding: 10,
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              ) : (
                <img
                  alt="logo"
                  src="/images/aryballe-tm-white-transparent.png"
                  style={{
                    padding: 5,
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              )}
            </FlexRow>
            {!isCollapsed && <span style={{ color: '#eee' }}>NeOse WebApp</span>}
          </FlexCol>
        )}
        <Menu
          theme="dark"
          mode={isMobile ? 'horizontal' : 'vertical'}
          style={{
            width: '100%',
          }}
          defaultActiveFirst
          items={routes
            .filter((route) => route.showInMenu)
            .map((route, index) => {
              return {
                key: route.key ?? index,
                icon: route.icon,
                label: <Link to={route.path as string}>{route.menuLabel}</Link>,
              };
            })}
        />
        <FlexRow
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            gap: 0,
          }}
        >
          {(function () {
            if (isBleApp()) {
              return (
                <div>
                  <CSMBleMessageDispatcher minimized={isCollapsed} />
                </div>
              );
            } else {
              return (
                <>
                  <div
                    style={{
                      borderTop: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                    }}
                  >
                    <NoaRefkitMessageDispatcher minimized={isCollapsed} />
                  </div>
                  <div
                    style={{
                      borderTop: '1px solid #ccc',
                    }}
                  >
                    <NoaThimphuMessageDispatcher minimized={isCollapsed} />
                  </div>
                </>
              );
            }
          })()}
        </FlexRow>
      </div>
    </Sider>
  );
};
