import { Menu, Row } from 'antd';
import { useState } from 'react';
import { SessionType } from '../../types/types';
import { RecordsManagement } from './Records/RecordsManagement';
import { ModelsTable } from './ModelsTable';

type RecordsAndModelsPageProps = {};

export const ModelsManagementPage: React.FC<RecordsAndModelsPageProps> = () => {
  const [sessionType, setSessionType] = useState<SessionType>(SessionType.Records);

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <Menu defaultSelectedKeys={[sessionType]} mode="horizontal" style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'center' }}>
          <Menu.Item key={SessionType.Records} onClick={() => setSessionType(SessionType.Records)}>
            {SessionType.Records}
          </Menu.Item>
          <Menu.Item key={SessionType.Models} onClick={() => setSessionType(SessionType.Models)}>
            {SessionType.Models}
          </Menu.Item>
        </Menu>
      </div>
      <Row>{sessionType === SessionType.Records ? <RecordsManagement /> : <ModelsTable />}</Row>
    </div>
  );
};
