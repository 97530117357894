import { DEFAULT_PLOT_DECIMATED_FPS, PLOT_WINDOW_SIZE } from '../../utils/constants/constants';
import { RecordValue, RecordValueWithSensograms, SENSOR_NATURE } from '../../services/cache/idb';
import { loadCorrectedMZI, loadFinalizeSignalEnvelope, saveCorrectedMZI, saveFinalizeSignalEnvelope } from '../../services/cache/localStorage';
import { QuestioningState } from '../../pages/OdorIdentificationPage/NoaOdorIdentificationContainer';
import { DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE } from '../serial/constants';
import { parseBiosensorsSignalEvent, parseEventPayload } from '../serial/csm';
import { SignatureWithSpotgrid, CSMMisoMessage, HIHvalues } from '../../types/types';
import { mean, standardDeviation, transpose } from './utils';

type signal = number[][]; // [sensor][time]
type signature = number[]; // [sensor]

const _extractSignature = (a: signal, baselineBounds: number[], plateauBounds: number[]): signature => {
  const baselineMeans = transpose(a.slice(...baselineBounds)).map((sig) => mean(sig));
  const analyteMeans = transpose(a.slice(...plateauBounds)).map((sig) => mean(sig));
  if (baselineMeans.length !== analyteMeans.length) {
    throw Error(`Number of sensors in baseline and plateau do not correspond: ${baselineMeans.length} vs ${analyteMeans.length}`);
  }
  const signature: number[] = [];
  for (let i = 0; i < analyteMeans.length; i++) {
    signature.push(analyteMeans[i] - baselineMeans[i]);
  }
  return signature;
};

const _extractDeltaSensor = (a: number[], baselineBounds: number[], plateauBounds: number[]): number | undefined => {
  if (a.length > 0) {
    const baselineMean = mean(a.slice(...baselineBounds));
    const analyteMean = mean(a.slice(...plateauBounds));
    return analyteMean - baselineMean;
  }

  return undefined;
};

export const normalizeL2 = (a: number[]): number[] => {
  let d = a.map((cur) => Math.pow(cur, 2));
  let dd = d.reduce((acc, cur) => (acc += cur), 0);
  let ddd = Math.sqrt(dd);
  return a.map((cur) => cur / ddd);
};

export const computeSignature = (record: RecordValueWithSensograms) => {
  if (record.baselineStart === undefined || record.baselineEnd === undefined) {
    throw Error('Baseline bounds are undefined');
  }
  if (record.analyteStart === undefined || record.analyteEnd === undefined) {
    throw Error('Analyte bounds are undefined');
  }
  const baselineBounds = [record.baselineStart, record.baselineEnd];
  const analyteBounds = [record.analyteStart, record.analyteEnd];
  const a = record.sensogramSeries;
  const signature = _extractSignature(a, baselineBounds, analyteBounds);
  return signature;
};

export const computeDeltaSensor = (record: RecordValueWithSensograms, kind: SENSOR_NATURE) => {
  if (record.baselineStart === undefined || record.baselineEnd === undefined) {
    throw Error('Baseline bounds are undefined');
  }
  if (record.analyteStart === undefined || record.analyteEnd === undefined) {
    throw Error('Analyte bounds are undefined');
  }

  const baselineBounds = [record.baselineStart, record.baselineEnd];
  const analyteBounds = [record.analyteStart, record.analyteEnd];

  let a: number[] = [];
  switch (kind) {
    case SENSOR_NATURE.Humidity:
      a = record.humiditySeries;
      break;
    case SENSOR_NATURE.Temperature:
      a = record.temperatureSeries;
      break;
    default:
      console.log('sensor kind unknown when computing delta sensor: ', kind);
  }

  const deltaSensor = _extractDeltaSensor(a, baselineBounds, analyteBounds);
  return deltaSensor;
};

export const correctHumidity = (a: number[], deltaHumidity: number, spotgrid: number[], humidityCalibrant: SignatureWithSpotgrid): number[] => {
  if (humidityCalibrant.deltaHumidity !== undefined) {
    let dh = humidityCalibrant.deltaHumidity;
    let affinities = Object.fromEntries(humidityCalibrant.spotsgrid.map((key, index) => [key, humidityCalibrant.signature[index] / dh]));
    return a.map((x, idx) => x - deltaHumidity * affinities[spotgrid[idx]]);
  }

  return [];
};

/*
Sort each signature in an array in spotName ASC order
*/
export const sortSignature = (spotsgrid1d: number[], signature: signature): [number[], signature] => {
  const spotsWithValues: [number, number][] = [];
  for (let i = 0; i < signature.length; i++) {
    spotsWithValues.push([spotsgrid1d[i], signature[i]]);
  }
  // console.log('before sort', spotsWithValues)
  spotsWithValues.sort();
  // console.log('after sort', spotsWithValues)
  let sortedSpotsgrid1d = spotsWithValues.map((e) => e[0]);
  let sortedSignature = spotsWithValues.map((e) => e[1]);

  return [sortedSignature, sortedSpotsgrid1d];
};

export const getAggregatedSpotsgridIndicesMap = (spotsgrid1d: number[]): Record<number, number[]> => {
  let _aggregationIndicesMap: Record<number, number[]> = {};
  if (!spotsgrid1d) {
    console.log('sense page: spotsgrid is empty');
    return _aggregationIndicesMap;
  }
  // Aggregate MZIs by peptide
  for (let i = 0; i < spotsgrid1d.length; i++) {
    let aggKey = spotsgrid1d[i];
    if (_aggregationIndicesMap[aggKey] === undefined) {
      _aggregationIndicesMap[aggKey] = [];
    }
    _aggregationIndicesMap[aggKey].push(i);
  }
  return _aggregationIndicesMap;
};

export const aggregateSignature = (signature: signature, spotsgrid1d: number[]): [signature, number[]] => {
  const _aggregationIndicesMap = getAggregatedSpotsgridIndicesMap(spotsgrid1d);
  const aggregatedSignature: signature = [];
  const aggregatedSpotsgrid1d: number[] = [];
  for (const [aggKey, indices] of Object.entries(_aggregationIndicesMap)) {
    aggregatedSignature.push(mean(indices.map((i) => signature[i])));
    aggregatedSpotsgrid1d.push(parseInt(aggKey));
  }
  return [aggregatedSignature, aggregatedSpotsgrid1d];
};

export const aggregateSensogramSpans = (sensogramSpans: number[][], spotsgrid1d: number[]): [number[][], number[]] => {
  const _aggregationIndicesMap = getAggregatedSpotsgridIndicesMap(spotsgrid1d);
  const aggregatedSensogramSpans: number[][] = [];
  const aggregatedSpotsgrid1d: number[] = [];
  for (const [aggKey, indices] of Object.entries(_aggregationIndicesMap)) {
    let aggregatedSensogramSpan: number[] = [];
    for (let i = 0; i < sensogramSpans[0].length; i++) {
      aggregatedSensogramSpan.push(mean(indices.map((j) => sensogramSpans[j][i])));
    }
    aggregatedSensogramSpans.push(aggregatedSensogramSpan);
    aggregatedSpotsgrid1d.push(parseInt(aggKey));
  }
  return [aggregatedSensogramSpans, aggregatedSpotsgrid1d];
};

export const softmaxForDistances = (distances: { [key: string]: number }): { [key: string]: number } => {
  // Get the distance values and negate them
  const values = Object.values(distances).map((distance) => -distance);

  // Find the maximum value for numerical stability
  const maxDistance = Math.max(...values);

  // Calculate the exponential of each element
  const expDistances = values.map((distance) => Math.exp(distance - maxDistance));

  // Calculate the sum of exponentials
  const sumExpDistances = expDistances.reduce((sum, value) => sum + value, 0);

  // Calculate the softmax probabilities and round them
  const softmaxValues = expDistances.map((expValue) => Math.round(100 * (expValue / sumExpDistances)));

  // Construct the result object with the same keys as the input
  const softmaxResult: { [key: string]: number } = {};
  Object.keys(distances).forEach((key, index) => {
    softmaxResult[key] = softmaxValues[index];
  });
  console.log('Softmax Probabilities:', softmaxResult);
  return softmaxResult;
};

/*
functions to handle MZI calculations
*/

// Process the MZI data and calculate averages
export const processMziData = (
  mzis: number[],
  rawMZISeriesRef: number[][],
  firstMZIsRef: number[] | null,
  currentSpotsgrid1d: number[] | null,
  humidityCompensationEnabled: boolean,
  humidityCalibrant: SignatureWithSpotgrid | undefined,
  humidityBaselineRef: number | null,
  hihValues: HIHvalues
): [number, number[]] | undefined => {
  if (!currentSpotsgrid1d) {
    console.log('Spots grid is empty');
    return;
  }

  rawMZISeriesRef.push(mzis);

  // calculate decimated MZI
  //console.log(rawMZISeriesRef.current)
  let decimatedMzis = calculateDecimatedMzis(currentSpotsgrid1d, rawMZISeriesRef);
  //console.log('mzi before applying corrections:', decimatedMzis)
  if (decimatedMzis !== undefined) {
    // Apply baseline correction, humidity compensation
    let correctedMZI = applyCorrections(decimatedMzis, firstMZIsRef, currentSpotsgrid1d, humidityCompensationEnabled, humidityCalibrant, humidityBaselineRef, hihValues);

    // Finalize the signal envelope average
    let finalizedSignalEnvelope = finalizeSignalEnvelope(correctedMZI);

    // push to local storage for cases where computing is not requested

    saveFinalizeSignalEnvelope(finalizedSignalEnvelope); // can be used elsewhere
    saveCorrectedMZI(correctedMZI); // can be used elsewhere
    return [finalizedSignalEnvelope, correctedMZI];
  }
};

// Determine if the data should be decimated. RD : and therefore update
export const shouldCompute = (timestamp: number, lastDecimationTickRef: React.MutableRefObject<number>) => {
  if (timestamp - lastDecimationTickRef.current >= 1000 / DEFAULT_PLOT_DECIMATED_FPS) {
    lastDecimationTickRef.current = timestamp;
    //console.log('true')
    return true;
  } else {
    //console.log('false')

    return false;
  }
};

// Calculate decimated MZI values by averaging over window size
const calculateDecimatedMzis = (currentSpotsgrid1d: number[] | null, rawMZISeriesRef: number[][]) => {
  if (currentSpotsgrid1d !== null) {
    let decimatedMzis = new Array(currentSpotsgrid1d.length).fill(0);

    rawMZISeriesRef.forEach((mziSeries) => {
      mziSeries.forEach((mzi, index) => {
        decimatedMzis[index] += mzi;
      });
    });

    return decimatedMzis.map((sum) => sum / rawMZISeriesRef.length);
  }
};

// Apply corrections like humidity compensation
const applyCorrections = (
  decimatedMzis: number[],
  firstMZIsRef: number[] | null,
  currentSpotsgrid1d: number[] | null,
  humidityCompensationEnabled: boolean,
  humidityCalibrant: SignatureWithSpotgrid | undefined,
  humidityBaselineRef: number | null,
  hihValues: HIHvalues
) => {
  //console.log('firstMZIsRef.current is:', firstMZIsRef.current)
  decimatedMzis.forEach((mzi, index) => {
    if (firstMZIsRef !== null) {
      decimatedMzis[index] -= firstMZIsRef[index];
    }
  });

  //console.log('humidityCompensationEnabled:', humidityCompensationEnabled)

  if (humidityCompensationEnabled && humidityCalibrant !== undefined && humidityBaselineRef !== null && currentSpotsgrid1d !== null) {
    return correctHumidity(decimatedMzis, hihValues.humidity - humidityBaselineRef, currentSpotsgrid1d, humidityCalibrant);
  } else {
    return decimatedMzis;
  }
};

// Finalize the signal envelope average and update state
const finalizeSignalEnvelope = (decimatedMzis: number[]) => {
  let lastFrame: number[] = decimatedMzis;
  //console.log('last frame is', lastFrame)
  let lastFrameSum: number = 0;
  for (let i = 0; i < lastFrame.length; i++) {
    lastFrameSum += lastFrame[i];
  }
  //console.log('finalMZIsSeries is', finalMZIsSeries)
  // building signalEnvelopeAvgRef
  let signalEnvelopeAvgRef = lastFrameSum / lastFrame.length;

  return signalEnvelopeAvgRef;
};

// compute questioningState
export const updateQuestioningState = (
  questioningState: React.MutableRefObject<QuestioningState>,
  averageMZI: number,
  averageMZISeriesRef: number[],
  decimatedMZISeriesCorrected: number[][],
  isOdorPresentRef: React.MutableRefObject<boolean>,
  maxOdorPresentValue: React.MutableRefObject<number>,
  odorPresenceThresholdLevelRef: React.MutableRefObject<number>
) => {
  if (!isOdorPresentRef.current) {
    // odor not present : compute noise and evaluate current mzi versus noise
    // compute noise if odor not present
    let noizeSeries = averageMZISeriesRef.slice(-4 * DEFAULT_PLOT_DECIMATED_FPS, -1 * DEFAULT_PLOT_DECIMATED_FPS);
    const NOISE_MULTIPLYING_FACTOR = 20; // to change
    let noizeLevelRef = mean(noizeSeries) + standardDeviation(noizeSeries) * NOISE_MULTIPLYING_FACTOR; // to do : provide slider to adjust this value
    console.log('noizeLevelRef.current is', noizeLevelRef);

    // check if current MZI higher than noise level to switch to analyte
    if (noizeLevelRef > 0 && averageMZI > noizeLevelRef) {
      let previousFrameMean: number = averageMZISeriesRef[averageMZISeriesRef.length - 2];
      odorPresenceThresholdLevelRef.current = mean([previousFrameMean, averageMZI]);
      isOdorPresentRef.current = true;
      questioningState.current = QuestioningState.AnalyteRecording;
      console.log('odor is detected and question state is changed');
    }
  } else {
    // odor is present
    // update max value
    maxOdorPresentValue.current = Math.max(maxOdorPresentValue.current, averageMZI);

    // to revert isOdorPresentRef and switch to sensor cleaning state, we need the current average mzi to be smaller than 0.3 x maxOdorPresentValue or mean of previous mzi with current (hence sharp drop)
    if (questioningState.current === QuestioningState.AnalyteRecording && averageMZI < Math.max(maxOdorPresentValue.current * DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE, odorPresenceThresholdLevelRef.current)) {
      isOdorPresentRef.current = false;
      maxOdorPresentValue.current = 0;
      odorPresenceThresholdLevelRef.current = 0;
      questioningState.current = QuestioningState.OdorAnalysis;
    }
  }
  //console.log('noizeLevelRef.current', noizeLevelRef.current.toFixed(3))
  //console.log('averageMZISeriesRef.current', averageMZISeriesRef.current)
};

export const updateKineticSeries = (
  averageMZI: number,
  correctedMZI: number[],
  averageMZISeriesRef: React.MutableRefObject<number[]>, // Pass the whole ref
  decimatedMZISeriesCorrected: React.MutableRefObject<number[][]> // Pass the whole ref
) => {
  // Update the .current values directly

  // Store the MZI average kinetics to update noise levels
  averageMZISeriesRef.current.push(averageMZI);
  if (averageMZISeriesRef.current.length > PLOT_WINDOW_SIZE) {
    averageMZISeriesRef.current.shift();
  }

  // Store the corrected MZI values for later algorithm work
  decimatedMZISeriesCorrected.current.push(correctedMZI);
  if (decimatedMZISeriesCorrected.current.length > PLOT_WINDOW_SIZE) {
    decimatedMZISeriesCorrected.current.shift();
  }
};

export const parseMessagePayload = (message: CSMMisoMessage) => {
  try {
    let eventPayload = parseEventPayload(message.message.Payload);
    return parseBiosensorsSignalEvent(eventPayload.Data);
  } catch (e) {
    if (e instanceof Error) {
      console.error('Failed to parse biosensors event:', e.message);
    } else {
      console.error('Unknown error occurred while parsing biosensors event');
    }
    return null;
  }
};
