import React, { createContext, useContext, useReducer } from 'react';
import { metadataReducer, initialState } from '../reducers/MetadataReducer';
import { thimphuProtocol } from '../../thimphu_protocol';
import { RefDesignProtocol } from '../../ref_design_protocol';
import { FspMetadata } from '../../fsp_metadata';
import { MetadataContextType } from '../types/MetadataTypes';

export const MetadataContext = createContext<MetadataContextType | undefined>(undefined);

export function useMetadataContext(): MetadataContextType {
  const context = useContext(MetadataContext);
  if (!context) {
    throw new Error('useMetadataContext must be used within a MessageProvider');
  }
  return context;
}

export function MetadataProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(metadataReducer, initialState);

  const setThimphuPlatformId = (platformId: thimphuProtocol.IPlatformIdResult) => {
    dispatch({
      type: 'SET_THIMPHU_PLATFORM_ID',
      payload: platformId,
    });
  };
  const setThimphuFspMetadata = (fspMetadata?: FspMetadata.IMetadataSchema) => {
    dispatch({
      type: 'SET_THIMPHU_FSP_METADATA',
      payload: fspMetadata,
    });
  };
  const setThimphuMemInfos = (memInfos: thimphuProtocol.IMemInfosResult) => {
    dispatch({
      type: 'SET_THIMPHU_MEM_INFOS',
      payload: memInfos,
    });
  };

  const setRefkitVersion = (version: RefDesignProtocol.IVersionResult) => {
    dispatch({
      type: 'SET_REFKIT_VERSION',
      payload: version,
    });
  };
  const setRefkitConfig = (config: RefDesignProtocol.IConfigData) => {
    dispatch({
      type: 'SET_REFKIT_CONFIG',
      payload: config,
    });
  };
  const setRefkitCapabilities = (capabilities: RefDesignProtocol.ICapabilitiesResult) => {
    dispatch({
      type: 'SET_REFKIT_CAPABILITIES',
      payload: capabilities,
    });
  };
  const setRefkitMemInfosImage = (memInfos: RefDesignProtocol.IMemoryInfoResult) => {
    dispatch({
      type: 'SET_REFKIT_MEM_INFOS_IMAGE',
      payload: memInfos,
    });
  };
  const setRefkitMemInfosCoreSensor = (memInfos: RefDesignProtocol.IMemoryInfoResult) => {
    dispatch({
      type: 'SET_REFKIT_MEM_INFOS_CORE_SENSOR',
      payload: memInfos,
    });
  };

  const contextValue: MetadataContextType = {
    thimphuPlatformId: state.thimphuPlatformId,
    thimphuFspMetadata: state.thimphuFspMetadata,
    thimphuMemInfos: state.thimphuMemInfos,

    refkitVersion: state.refkitVersion,
    refkitConfig: state.refkitConfig,
    refkitCapabilities: state.refkitCapabilities,
    refkitMemInfosImage: state.refkitMemInfosImage,
    refkitMemInfosCoreSensor: state.refkitMemInfosCoreSensor,

    setThimphuPlatformId,
    setThimphuFspMetadata,
    setThimphuMemInfos,

    setRefkitVersion,
    setRefkitConfig,
    setRefkitCapabilities,
    setRefkitMemInfosImage,
    setRefkitMemInfosCoreSensor,
  };

  return <MetadataContext.Provider value={contextValue}>{children}</MetadataContext.Provider>;
}
