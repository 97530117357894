import { ReloadOutlined } from '@ant-design/icons';
import { Button, Col, Progress, Row, Switch } from 'antd';
import React from 'react';
import { HIHvalues } from '../../../types/types';
import { ARYBALLE_COLOR_BLUE, ARYBALLE_COLOR_BLUE_DARK, ARYBALLE_COLOR_CYAN, ARYBALLE_COLOR_GRAY, colorPalette } from '../../../utils/helpers/utils';

interface IntensityGaugeProps {
  MZIvalue: number;
  hihValues?: HIHvalues;
}

const MIN_VALUE = -7; // Define minimum value for the gauge
const MAX_VALUE = 7; // Define maximum value for the gauge
const mapValueToPercent = (value: number): number => {
  // Map the value (-100 to 100) to a percentage (0 to 100)
  return ((value - MIN_VALUE) / (MAX_VALUE - MIN_VALUE)) * 100;
};

export const IntensityGauge: React.FC<IntensityGaugeProps> = ({ MZIvalue, hihValues }) => {
  return (
    <>
      <Row justify="center" style={{ marginBottom: '30px' }}>
        <Progress
          type="dashboard"
          percent={mapValueToPercent(MZIvalue)}
          format={() => (
            <>
              <p style={{ color: ARYBALLE_COLOR_BLUE_DARK }}>{parseFloat(MZIvalue.toString())}</p>
              <p style={{ fontSize: 14, color: ARYBALLE_COLOR_BLUE_DARK }}>Average Intensity (rad)</p>
            </>
          )}
          gapDegree={60}
          strokeColor={{
            '0%': ARYBALLE_COLOR_CYAN,
            '30%': ARYBALLE_COLOR_CYAN,
            '70%': ARYBALLE_COLOR_BLUE,
            '99%': ARYBALLE_COLOR_BLUE_DARK,
            '120%': ARYBALLE_COLOR_CYAN,
          }}
          strokeLinecap="round"
          trailColor={ARYBALLE_COLOR_GRAY}
          width={250}
        />
      </Row>
      {hihValues && hihValues.temperature && (
        <Row justify="center">
          <p style={{ fontSize: 16, lineHeight: '22px' }}>Temperature: {parseFloat(hihValues.temperature.toFixed(1))} °C </p>
        </Row>
      )}
    </>
  );
};
