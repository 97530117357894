import { callAPIEndpoint } from './api';

export const apiPostUser = async (id: string, email: string, firstName: string, lastName: string, groups: string[]) => {
  void (await callAPIEndpoint(
    '/user',
    'POST',
    JSON.stringify({
      ID: id,
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Groups: groups.map((groupName) => ({
        Name: groupName,
      })),
    })
  ));
};
