import { Card } from 'antd';
import { CSSProperties, FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

export const Paper: FC<{ children?: ReactNode; style?: CSSProperties }> = ({ children, style }) => {
  return (
    <div className="paper" style={style}>
      {children}
    </div>
  );
};

export const FlexRow: FC<{ children?: ReactNode; style?: CSSProperties }> = ({ children, style }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export const FlexCol: FC<{ children?: ReactNode; style?: CSSProperties }> = ({ children, style }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export const CardLink: FC<{
  to: string;
  title: string;
  icon: JSX.Element;
}> = ({ to, title, icon }) => {
  return (
    <Link to={to}>
      <Card
        style={{
          height: '100%',
          backgroundColor: '#1890FF10',
          padding: 5,
        }}
        hoverable={true}
      >
        <FlexCol
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {icon}
          <h3 style={{ fontSize: 16, textAlign: 'center' }}>{title}</h3>
        </FlexCol>
      </Card>
    </Link>
  );
};
