import { getAPIBaseURL } from '../../config/api';
import { ApiErrorType, ApiResponseType } from '../../types/types';

export * from './userApi';
export * from './recordApi';
export * from './partitionApi';
export * from './sessionApi';

export const callAPIEndpoint = async (path: string, method: string, body?: BodyInit, responseType?: ApiResponseType, bearerToken?: string) => {
  const response = await fetch(`${getAPIBaseURL()}${path}`, {
    method: method,
    body: body,
    headers: [['Authorization', `Bearer ${bearerToken}`]],
  });
  if (!response.ok) {
    if (response.status === 500) {
      const errorData: ApiErrorType = await response.json();
      throw new Error(`API call failed: ${response.status} ${response.statusText}: ${errorData.Reason}`);
    }
    throw new Error(`API call failed: ${response.status} ${response.statusText}`);
  }
  switch (responseType) {
    case undefined:
      return await response.json();
    case ApiResponseType.JSON:
      return await response.json();
    case ApiResponseType.ArrayBuffer:
      return await response.arrayBuffer();
  }
};
