import { FC } from 'react';
import { FlexRow, FlexCol } from '../common/common';
import { LoginWidget } from '../../pages/LoginPage';
import { useMediaQuery } from '../../utils/helpers/utils';
import { Header } from 'antd/es/layout/layout';
import { useAppTheme } from '../../hook/useAppTheme';

export const AppHeader: FC = () => {
  const isMobile = useMediaQuery();
  const { colorBgContainer } = useAppTheme();

  return (
    <Header style={{ padding: 0, background: colorBgContainer, position: 'sticky', top: 0, zIndex: 500 }}>
      <FlexRow style={{ justifyContent: isMobile ? 'space-between' : 'end', alignItems: 'center', height: '100%', paddingRight: 20 }}>
        {isMobile && (
          <FlexCol style={{ alignItems: 'center', justifyContent: 'center', gap: 0 }}>
            <FlexRow style={{ height: '64px', justifyContent: 'center' }}>
              <img alt="logo" src="/images/logo292.png" style={{ padding: 10, maxWidth: '100%', maxHeight: '100%' }} />
            </FlexRow>
          </FlexCol>
        )}
        <LoginWidget />
      </FlexRow>
    </Header>
  );
};
