import { ApiParitionDataType, ApiResponseType } from '../../types/types';
import { getPartition, PartitionValue, RecordValue } from '../cache/idb';
import { callAPIEndpoint } from './api';
import { parse as uuidParse, stringify as uuidStringify } from 'uuid';

export const encodeApiPartition = (partition: PartitionValue, type: ApiParitionDataType): Uint8Array => {
  // encode partition as a byte array
  // key (uuid): 16 bytes
  // type: 1 byte (1 = sensogram, 2 = humidity, 3 = temperature)
  // absoluteTimestamp (unix timestamp): 8 bytes
  // nFrames (uint32): 2 bytes
  // nDims (uint32): 2 bytes
  // relativeTimestamps (array of uint16): 2 * nFrames bytes
  // sensorgram : series (array of fixed12 encoded as uint16): 2 * nFrames * nDims bytes
  // or
  // sensors : series (array of float32): 4 * nFrames * nDims bytes

  // all numbers are big endian
  // init for sensorgram partitions
  let totalLength = 16 + 1 + 8 + 2 + 2 + 2 * partition.nFrames + 2 * partition.nFrames * partition.nDims;

  // update if sensors that are float32 encoded
  if (type !== ApiParitionDataType.Sensogram) {
    totalLength = 16 + 1 + 8 + 2 + 2 + 2 * partition.nFrames + 4 * partition.nFrames * partition.nDims;
  }

  const arrayBuffer = new ArrayBuffer(totalLength); // underlying array buffer
  const dataView = new DataView(arrayBuffer); // view of the (shared) array buffer

  // key
  const keyBytes = new Uint8Array(uuidParse(partition.key).buffer);
  for (let i = 0; i < 16; i++) {
    dataView.setUint8(i, keyBytes[i]);
  }

  // type
  dataView.setUint8(16, +type);

  //  absolute timestamp
  dataView.setBigUint64(16 + 1, BigInt(partition.absoluteTimestamp));
  // console.log('encoding partition: absolute timestamp', partition.absoluteTimestamp, dataView.getBigUint64(17))

  // nFrames
  dataView.setUint16(16 + 1 + 8, partition.nFrames);

  // nDims
  dataView.setUint16(16 + 1 + 8 + 2, partition.nDims);

  // relative timestamps
  let relativeTimestampsOffset = 16 + 1 + 8 + 2 + 2;
  for (let i = 0; i < partition.relativeTimestamps.length; i++) {
    dataView.setUint8(relativeTimestampsOffset + i, partition.relativeTimestamps[i]);
  }

  // series
  let seriesOffset = 16 + 1 + 8 + 2 + 2 + 2 * partition.nFrames;
  for (let i = 0; i < partition.series.length; i++) {
    dataView.setUint8(seriesOffset + i, partition.series[i]);
  }

  // console.log('encoded partition', arrayBuffer);

  return new Uint8Array(arrayBuffer);
};

export const decodeApiPartition = (arrayBuffer: ArrayBuffer): PartitionValue => {
  let dataView = new DataView(arrayBuffer);

  // key
  let key = uuidStringify(new Uint8Array(arrayBuffer.slice(0, 16)));

  // type
  let type = dataView.getUint8(16);

  // absolute timestamp
  let absoluteTimestamp = Number(dataView.getBigUint64(16 + 1));

  // nFrames
  let nFrames = dataView.getUint16(16 + 1 + 8);

  // nDims
  let nDims = dataView.getUint16(16 + 1 + 8 + 2);

  // relative timestamps
  let relativeTimestampsOffset = 16 + 1 + 8 + 2 + 2;
  let relativeTimestamps = new Uint8Array(arrayBuffer.slice(relativeTimestampsOffset, relativeTimestampsOffset + 2 * nFrames));

  // series
  let seriesOffset = 16 + 1 + 8 + 2 + 2 + 2 * nFrames;
  let seriesTypeSizeCoeff = 1;

  // if type is not a sensorgram then values are encoded on 4 bytes (float32), not 2 (fixed12 as uint16)
  if (type !== 1) {
    seriesTypeSizeCoeff = 2;
  }
  let series = new Uint8Array(arrayBuffer.slice(seriesOffset, seriesOffset + 2 * nFrames * nDims * seriesTypeSizeCoeff));

  let partition: PartitionValue = {
    key: key,
    absoluteTimestamp: absoluteTimestamp,
    nFrames: nFrames,
    nDims: nDims,
    relativeTimestamps: relativeTimestamps,
    series: series,
  };
  return partition;
};

const apiPostPartition = async (partition: PartitionValue, recordID: string, type: ApiParitionDataType) => {
  console.log('posting partition', partition);
  let encodedPartition = encodeApiPartition(partition, type);
  void (await callAPIEndpoint(`/partition?record_id=${recordID}`, 'POST', encodedPartition));
};

const apiCheckPartitionExists = async (partitionKey: string) => {
  let partitionKeyExists: {
    Exists: boolean;
  } = await callAPIEndpoint(`/partition/exists?partition_id=${partitionKey}`, 'GET');
  return partitionKeyExists.Exists;
};

export const apiPostRecordPartitions = async (record: RecordValue) => {
  if (!record.sensogramPartitionKeys) {
    return;
  }
  let tasks = [];

  // push sensorgrams partitions
  for (let partitionKey of record.sensogramPartitionKeys) {
    tasks.push(async () => {
      try {
        if (await apiCheckPartitionExists(partitionKey)) {
          console.log('partition already exists, skipping', partitionKey);
          return;
        }
        let partition = await getPartition(partitionKey);
        await apiPostPartition(partition, record.key, ApiParitionDataType.Sensogram);
      } catch (e: any) {
        throw new Error(`error posting sensogram partition ${partitionKey}: ${e.message}`);
      }
    });
  }

  // push humidity partitions
  if (record.humidityPartitionKeys !== undefined) {
    for (let partitionKey of record.humidityPartitionKeys) {
      // console.log("posting humidity partition", partitionKey)
      tasks.push(async () => {
        try {
          if (await apiCheckPartitionExists(partitionKey)) {
            console.log('humidity partition already exists, skipping', partitionKey);
            return;
          }
          let partition = await getPartition(partitionKey);
          await apiPostPartition(partition, record.key, ApiParitionDataType.Humidity);
        } catch (e: any) {
          throw new Error(`error posting humidity partition ${partitionKey}: ${e.message}`);
        }
      });
    }
  }

  // push temperature partitions
  if (record.temperaturePartitionKeys !== undefined) {
    for (let partitionKey of record.temperaturePartitionKeys) {
      tasks.push(async () => {
        try {
          if (await apiCheckPartitionExists(partitionKey)) {
            console.log('partition already exists, skipping', partitionKey);
            return;
          }
          let partition = await getPartition(partitionKey);
          await apiPostPartition(partition, record.key, ApiParitionDataType.Temperature);
        } catch (e: any) {
          throw new Error(`error posting temperature partition ${partitionKey}: ${e.message}`);
        }
      });
    }
  }

  try {
    await Promise.all(tasks.map((task) => task()));
  } catch (e: any) {
    console.error('error posting all record partitions', e.message);
  }
};

const _apiGetRecordPartitionKeys = async (recordKey: string, partitionDataType: ApiParitionDataType) => {
  let partitionKeys: string[] = await callAPIEndpoint(`/record/partition/ids?record_id=${recordKey}&partition_data_type=${partitionDataType}`, 'GET');
  return partitionKeys;
};

export const apiGetRecordSensogramPartitionKeys = async (recordKey: string) => {
  return await _apiGetRecordPartitionKeys(recordKey, ApiParitionDataType.Sensogram);
};

export const apiGetRecordHumidityPartitionKeys = async (recordKey: string): Promise<string[] | null> => {
  return await _apiGetRecordPartitionKeys(recordKey, ApiParitionDataType.Humidity);
};

export const apiGetRecordTemperaturePartitionKeys = async (recordKey: string): Promise<string[] | null> => {
  return await _apiGetRecordPartitionKeys(recordKey, ApiParitionDataType.Temperature);
};

export const apiGetRecordPartition = async (partitionKey: string) => {
  let encodedPartition: ArrayBuffer = await callAPIEndpoint(`/partition?partition_id=${partitionKey}`, 'GET', undefined, ApiResponseType.ArrayBuffer);
  return decodeApiPartition(encodedPartition);
};
