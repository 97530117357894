import { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from '../../config/routes';
import { Content } from 'antd/es/layout/layout';
import { useMediaQuery } from '../../utils/helpers/utils';

export const AppContent: FC = () => {
  const isMobile = useMediaQuery();
  const minHeightOffset = isMobile ? 200 : 114;

  return (
    <Content
      style={{
        marginTop: 16,
        marginLeft: 16,
        marginRight: 16,
        marginBottom: 24,
        padding: isMobile ? 0 : 24,
        overflow: 'initial',
        minHeight: `calc(100vh - ${minHeightOffset}px)`,
      }}
    >
      <Switch>
        <Route path="/signout/callback">
          <Redirect to="/" />
        </Route>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} exact={route.exact} component={route.component} />
        ))}
      </Switch>
    </Content>
  );
};
