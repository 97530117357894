import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Matrix } from 'ml-matrix';
import { loadGenericModel, loadSpotsGrid1D } from '../../../services/cache/localStorage';
import { classifySignatureChemical } from '../../analysis/classifier';
import { Paper } from '../../common/common';
import { mean } from '../../analysis/utils';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const DEFAULT_PLOTLY_MARGIN: Plotly.Layout['margin'] = {
  t: 30,
  b: 40,
  l: 40,
  r: 40,
};

const DISPLAY_RADIAN_THRESHOLD = 0.3;

interface DataProcessorProps {
  rawSignalFromApp: number[] | null;
  seriesLabels: number[] | null;
  setCurrentProba: React.Dispatch<React.SetStateAction<SecondData[]>>;
  setCurrentChemicalFamily: React.Dispatch<React.SetStateAction<string>>;
}

interface PeptideContent {
  peptide: string;
  ratio: number;
}

interface SecondData {
  id: string;
  content: PeptideContent[];
}

// define enum
export enum ModelCategory {
  Barycenters = 'Barycenters',
  ComparisonSignatures = 'ComparisonSignatures',
  ComparisonIntensities = 'ComparisonIntensities',
  ChemicalPrediction = 'ChemicalPrediction',
}

// Define the types for metadata
export interface Metadata {
  created_at: string;
  spotfile: string[];
  ID: string;
  type?: ModelCategory;
  debug?: boolean;
}

// Define the types for barycenters
export interface Barycenters {
  [key: string]: [number, number];
}

// Define the main data structure of generic models
export interface GenericModel {
  metadata: Metadata;
  projection_axis?: number[][];
  barycenters?: Barycenters;
  referencesSignatures?: number[][];
  referenceIntensities?: number[];
  comparisonThreshold?: number;
  analyteDuration?: number;
  chemicalRules?: ClassificationRules;
  barycentersAsSignatures?: Record<string, number[]>[];
}

// Define interfaces for the YAML structure
interface Rule {
  condition: string;
  result: string;
}

interface ClassificationRules {
  rules: Rule[];
  default: string;
}

const DataProcessor: React.FC<DataProcessorProps> = ({ rawSignalFromApp, seriesLabels, setCurrentProba, setCurrentChemicalFamily }) => {
  const [plotlyData, setPlotlyData] = useState<Partial<Plotly.Data>[]>([]);
  const [plotlyLayout, setPlotlyLayout] = useState<Partial<Plotly.Layout>>({});
  const [pcaEigenvectors, setPcaEigenvectors] = useState<number[][]>([]);
  const [barycentersSignature, setBarycentersSignature] = useState<Record<string, number[]>>({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [normalizedSignalDisplay, setNormalizedSignalDisplay] = useState<number[]>([]);
  const [barycentersCoordinates, setBarycentersCoordinates] = useState<Record<string, number[]>>({});
  const [currentModel, setCurrentModel] = useState<GenericModel | null>(null);
  const [currentSpotsgrid1d, setCurrentSpotsgrid1d] = useState<number[] | null>(null);

  const pcaTransform = (signatures: number[][], eigvec: number[][], nComponents: number): number[][] => {
    const U = new Matrix(eigvec);
    const dataset = new Matrix(signatures);
    const predictions = dataset.mmul(U);
    return predictions.subMatrix(0, predictions.rows - 1, 0, nComponents - 1).to2DArray();
  };

  const generateColorMap = (items: any[]) => {
    const colors = ['#FFD12C', '#7F7FFF', '#FF5733', '#C70039', '#900C3F', '#581845', '#FFC300', '#DAF7A6', '#581845', '#1ABC9C', '#2E86C1', '#A569BD'];
    const uniqueItems = Array.from(new Set(items));
    const colorMap: { [key: string]: string } = {};
    uniqueItems.forEach((item, index) => {
      colorMap[item] = colors[index % colors.length];
    });
    return colorMap;
  };

  // const updatePlot = (groupedProjections: Record<string, number[][]>, colors: Record<string, string>) => {
  //   const barycenters = computeBarycenters(groupedProjections);
  const updatePlot = (barycenters: Record<string, number[]>, colors: Record<string, string>) => {
    console.log('barycenters x,y', barycenters);
    const uniqueLabels = Object.keys(barycenters);

    const plotData: Partial<Plotly.ScatterData>[] = [
      {
        type: 'scatter',
        mode: 'markers',
        x: uniqueLabels.map((label) => barycenters[label][0]),
        y: uniqueLabels.map((label) => barycenters[label][1]),
        marker: {
          size: 10,
          color: uniqueLabels.map((label) => colors[label]),
        },
        text: uniqueLabels,
        hoverinfo: 'text',
      },
    ];

    setPlotlyData(plotData);

    const layout: Partial<Plotly.Layout> = {
      ...DEFAULT_PLOTLY_MARGIN,
      hovermode: 'closest',
      xaxis: { title: { text: 'First Component', font: { size: 12 } } },
      yaxis: { title: { text: 'Second Component', font: { size: 12 } } },
      dragmode: 'zoom',
      showlegend: false,
    };

    setPlotlyLayout(layout);
  };

  const normalizeSignal = (rawSignal: number[], seriesLabels: number[]): number[] => {
    // 1. Check that rawSignal is the same size as seriesLabels
    if (rawSignal.length !== seriesLabels.length) {
      throw new Error(`Raw signal length (${rawSignal.length}) does not match series labels length (${seriesLabels.length})`);
    }

    // 2. Locate the indices of 1 and 36 in seriesLabels
    const spot1Index = seriesLabels.indexOf(1);
    const spot36Index = seriesLabels.indexOf(36);

    // Validate that we found both indices
    if (spot1Index === -1 || spot36Index === -1) {
      throw new Error('Could not find required series labels (1 or 36)');
    }

    // 3. Compute normalized values
    const spot1Value = rawSignal[spot1Index];
    const spot36Value = rawSignal[spot36Index];

    const normalizedSignal = rawSignal.map((value) => (value - spot1Value) / (spot36Value - spot1Value));

    return normalizedSignal;
  };

  const reorderPeptides = (signal: number[], oldSeriesLabel: string[], newSeriesLabel: string[]): number[] => {
    let reorderedSignal: number[] = [];

    for (let label of newSeriesLabel) {
      reorderedSignal.push(signal[oldSeriesLabel.indexOf(label)]);
    }

    return reorderedSignal;
  };

  // handle model load from store
  useEffect(() => {
    let _model = loadGenericModel();
    console.log(' Selected Model is :', _model);

    if (_model !== null) {
      setCurrentModel(_model);
      setPcaEigenvectors(_model.projection_axis || []);

      // update plot with barycenters if defined
      if (_model.barycenters !== undefined) {
        setBarycentersCoordinates(_model.barycenters);
        if (_model.barycentersAsSignatures) setBarycentersSignature(_model.barycentersAsSignatures[0]);

        // update plot with pca data
        let chemicalFamilies = Object.keys(_model.barycenters);
        const colors = generateColorMap(chemicalFamilies);
        updatePlot(_model.barycenters, colors);
      }
    }

    // load spotgrid 1D for display in front end
    let _spotsgrid1d = loadSpotsGrid1D();
    setCurrentSpotsgrid1d(_spotsgrid1d);

    setIsDataLoaded(true);
  }, []);

  // Handle raw signature updates
  useEffect(() => {
    if (!rawSignalFromApp || rawSignalFromApp === null || pcaEigenvectors.length === 0 || !isDataLoaded || currentModel === null) return;

    // compute signature if mean if higher than 1
    const mean = (arr: number[]) => arr.reduce((a, b) => a + b, 0) / arr.length;

    if (seriesLabels && rawSignalFromApp && Math.abs(mean(rawSignalFromApp)) >= DISPLAY_RADIAN_THRESHOLD) {
      // const rawSignal = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120];
      const normalizedSignal = normalizeSignal(rawSignalFromApp, seriesLabels);
      setNormalizedSignalDisplay(normalizedSignal);
      const reorderedNormalisedSignal = reorderPeptides(
        normalizedSignal,
        seriesLabels.map((x) => x.toString()),
        currentModel.metadata.spotfile
      );

      // Project the new point using stored eigenvectors
      // const newProjection = pcaTransform([reorderedNormalisedSignal], pcaEigenvectors, 2)[0];

      // Update plot with both base projections and new point
      // const updatedPlotlyData = [...plotlyData];

      // Add or update the new point trace
      // const newPointTrace: Partial<Plotly.ScatterData> = {
      //   type: 'scatter',
      //   mode: 'markers',
      //   x: [newProjection[0]],
      //   y: [newProjection[1]],
      //   marker: {
      //     size: 12,
      //     color: 'red',
      //     symbol: 'star',
      //   },
      //   name: 'Current Signal',
      //   hoverinfo: 'name',
      // };

      // If there's already a current signal point, update it, otherwise add it
      // const currentSignalIndex = updatedPlotlyData.findIndex((trace) => trace.name === 'Current Signal');
      // if (currentSignalIndex !== -1) {
      //   updatedPlotlyData[currentSignalIndex] = newPointTrace;
      // } else {
      //   updatedPlotlyData.push(newPointTrace);
      // }

      // setPlotlyData(updatedPlotlyData);

      try {
        if (currentModel !== null) {
          const prediction = classifySignatureChemical(currentModel, normalizedSignal, seriesLabels)[0];
          console.log('prediction is', prediction);
          // setCurrentChemicalFamily(prediction);
          const packagedData: SecondData[] = [
            {
              id: 'example-id', // You can use any unique ID here
              content: Object.entries(currentModel.barycenters || {}).map(([key, value]) => ({
                peptide: key,
                ratio: key === prediction ? 35 : 7, // hardcode found chemical family : size 3 other wise 1.
              })),
            },
          ];
          setCurrentProba(packagedData);
        }
      } catch (error) {
        console.error(error);
      }

      // ** distance computation

      // const distances: Record<string, number> = computeDistances(reorderedNormalisedSignal, barycentersSignature);
      // console.log('distances', distances);

      // const probabilities: Record<string, number> = softmax(distances);
      // console.log('proba', probabilities);

      // console.log('packagedData', packagedData);
      // Update state with the packaged data
    } else {
      let chemicalFamilies = Object.keys(barycentersCoordinates);

      const packagedData: SecondData[] = [
        {
          id: 'example-id', // You can use any unique ID here
          content: chemicalFamilies.map((family) => ({
            peptide: family,
            ratio: 7,
          })),
        },
      ];
      // setCurrentChemicalFamily('unknown');
      setCurrentProba(packagedData);
    }
  }, [rawSignalFromApp, pcaEigenvectors, isDataLoaded]);

  // function softmax(distances: Record<string, number>): Record<string, number> {
  //   const expDistances = Object.values(distances).map((distance) => Math.exp(-distance)); // Negative for inverse relationship
  //   const sumExpDistances = expDistances.reduce((sum, val) => sum + val, 0);
  //   const scaling_factor = 50;
  //   const probabilities: Record<string, number> = {};
  //   Object.keys(distances).forEach((key, index) => {
  //     probabilities[key] = (scaling_factor * expDistances[index]) / sumExpDistances;
  //   });

  //   return probabilities;
  // }

  // function euclideanDistance(vec1: number[], vec2: number[]): number {
  //   return Math.sqrt(vec1.reduce((sum, val, index) => sum + Math.pow(val - vec2[index], 2), 0));
  // }

  // function computeDistances(normalizedSignal: number[], barycentersSignature: Record<string, number[]>): Record<string, number> {
  //   const distances: Record<string, number> = {};

  //   for (const key in barycentersSignature) {
  //     distances[key] = euclideanDistance(normalizedSignal, barycentersSignature[key]);
  //   }

  //   return distances;
  // }

  return (
    <Paper style={{ width: '100%', margin: 'auto' }}>
      <Collapse>
        <Panel header="Normalized Signal" key="1">
          <div>
            <h2>Normalized signal:</h2>
            {normalizedSignalDisplay.map((value, index) => (
              <span key={index}>
                <strong>{currentSpotsgrid1d && currentSpotsgrid1d[index]}</strong>: {value.toFixed(2)}{' '}
              </span>
            ))}
          </div>
        </Panel>
      </Collapse>
    </Paper>
  );
};

export default DataProcessor;
