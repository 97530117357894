import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const useDisplayMode = () => {
  useEffect(() => {
    const handleDisplayMode = () => {
      let displayMode = 'browser-tab';
      if (window.matchMedia('(display-mode: standalone)').matches) {
        displayMode = 'standalone';
      }
      console.log('app root: display mode is', displayMode);
      if (displayMode === 'browser-tab') {
        toast("You can install this app on your device by clicking on the 'Add to Home Screen' button in your browser menu.");
      }
    };

    window.addEventListener('DOMContentLoaded', handleDisplayMode);
    // return () => {
    //   window.removeEventListener('DOMContentLoaded', handleDisplayMode);
    // };
  }, []);
};
